s
<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>

    <div class="container-fluid mt-6" style="background-color: white">
      
      <div>
        
        <div class="col-12" style="padding-left: 0px; padding-right: 7px">
        <div class="row">
          <div class="col-md-4 mb-2 mt-2 pl-1">
            <router-link to="/level4/activity/add4.4.1">
              <AddButton />
            </router-link>
          </div>

          <DeleteButton @bulkActions="bulkActions" />
        </div>
</div>
        <h2 style="font-size: 1.5rem">Comprehension ({{ levelLenght }})</h2>
        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 col-md-6 p-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-6 float-right p-0"
              style="margin-top: 3px"
            >
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right float-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="table-responsive">
              <form
                method="post"
                @submit.prevent="changeStatus"
                @keydown="form.onKeydown($event)"
                enctype="multipart/form-data"
              >
                <input type="hidden" v-model="status" name="status" id="" />
                <b-table
                  :items="category"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  ref="selectableTable"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  empty-text="No record found"
                  empty-filtered-text="No search result found"
                  @filtered="onFiltered"
                >
                  <template #head(id)>
                    <input
                      type="checkbox"
                      v-model="allSelected"
                      @change="selectAll"
                    />
                  </template>

                  <template v-slot:cell(id)="category">
                    <input
                      type="checkbox"
                      v-model="selected"
                      :value="category.item.id"
                      name="id[]"
                      number
                    />
                  </template>
                  <template v-slot:cell(title)="category">
                    <span>{{ category.item.title }}</span>
                  </template>
                  <template v-slot:cell(t_sound_file)="category">
                    <TurkishSoundTag
                      :imgUrl="imgUrl"
                      :soundUrl="category.item.t_sound_file"
                    />
                  </template>
                  <template v-slot:cell(action)="category">
                    <router-link
                      :to="{
                        name: 'Edit question4.4.1',
                        params: { id: category.item.id },
                      }"
                    >
                      <font-awesome-icon
                        style="font-size: 16px; padding: 0 4px"
                        icon="fa-solid fa-pencil"
                      />
                    </router-link>
                    <a
                      @click="deleteQuestion(category.item.id)"
                      style="font-size: 16px; color: #dc3545"
                    >
                      <font-awesome-icon
                        style="font-size: 16px; padding: 0 4px"
                        icon="fa-solid fa-trash-can"
                      />
                    </a>
                  </template>
                </b-table>
              </form>

              <br />
              <div class="col-sm-12 col-md-6 float-right p-0" style="">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    float-right
                    mb-3
                  "
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterA from "../../ToasterA";
import ToasterU from "../../ToasterU";
import Swal from "sweetalert2";
import ToasterAc from "../../ToasterAc";
import ToasterDe from "../../ToasterDe";
import ToasterD from "../../ToasterD";
import AddButton from "../../ButtonComponent/AddButton.vue";
import DeleteButton from "../../ButtonComponent/DeleteButton.vue";
import TurkishSoundTag from "../../Components/TurkishSoundTag.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    ToasterDe,
    ToasterAc,
    DeleteButton,
    AddButton,
    ToasterD,
    TurkishSoundTag,
  },

  data() {
    return {
      //   inputs: [
      //     {
      //       options: "",
      //     },
      //   ],

      errors: [],
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      toasterac: false,
      toasterde: false,
      toasterd: false,
      rows: "",

      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,

      imgUrl: this.$appName,

      toaster: false,
      toasterprop: false,
      answer: "",
      optionName: "",
      optionID: "",
      sublevel_id: "",
      sublevel_name: "",
      img_folder: "/images/",
      base_url: this.$appName,
      category: [],
      level_id: "",
      subLevelName: "",
      form: new Form({
        id: "",
        optionID: "",
        image: "",
        name: "3.3.4",
        answer: "",
        status: "",
        option: "",
        sublevel_id: "",
        level_id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        // {
        //   key: "name",
        //   label: "Name",
        //   tdClass: "align-middle",
        // },

        {
          key: "title",
          label: "Title",
          tdClass: "align-middle",
        },

        // {
        //   key: "t_sentence",
        //   label: "Turkish Sentence",
        //   tdClass: "align-middle",
        // },
        // {
        //   key: "e_sentence",
        //   label: "English Sentence",
        //   tdClass: "align-middle",
        // },
        
      {
          key: "t_sound_file",
          label: "Turkish Sound File",
          tdClass: "align-middle",
        },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  created() {
    //   axios
    //   .get(this.$appName + `/api/activity/edit1/${this.$route.params.idd}`)
    //   .then((res) => {
    //     if (res.data.id != undefined) {
    //       this.form = res.data;
    //       this.$route.params.id = res.data.id;
    //       this.inputs = res.data.option;
    //       this.getActivity();
    //     }
    //   });
  },

  mounted() {
    this.getActivity();
  },

  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },
    getActivity() {
      axios.get(this.$appName + `/api/activity/fourthLevel6`).then((res) => {
        console.log("dddd", res.data.item);
        this.category = res.data;
        this.levelLenght = res.data.length;
        this.rows = res.data.length;
      });
    },

    selectId(e) {
      this.form.answer = e.optionA;
    },

    saveActivityID() {
      // alert(this.$route.params.id);
      //   localStorage.setItem("activityID", this.$route.params.id);
      //   this.$router.push({
      //     path: "/level3/question/add1",
      //   });
    },

    bulkActions(type) {
      if (type == "delete") {
        this.status = "delete";
      }
      this.changeStatus();
    },
    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;
            axios
              .post(this.$appName + "/api/activity/statusChange", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;
                this.toasterd = true;
                this.getActivity();
                this.allSelected = false;
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },

    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;
        this.count = this.category.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },
    deleteQuestion(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          axios
            .delete(this.$appName + `/api/activity/Activity/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.category.map((data) => data.id).indexOf(id);
              this.category.splice(i, 1);
              this.loader = false;
              this.isDelete = false;
              this.levelLenght = this.category.length;
              this.rows = this.category.length;
            });
        }
      });
    },
  },
};
</script>

<style></style>
