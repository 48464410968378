<template>
      <loader
      class="loader"

      object="#fb4d46" 
      color1="#1f7dad" 
      color2="#ffffff"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
</template>

<script>
export default {
  name: "Loader1",

}
</script>