<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <br />

      <BackButton />
      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Question</h2>
        <h2 v-else style="font-size: 1.5rem">Add Question</h2>
      </div>
      <form
        method="post"
        @submit.prevent="AddQuestion"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />
       

        <label class="lablestyle">
          Question (Turkish)<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.question"
          @change="questionSearch"
          class="form-control"
          type="text"
          name="name"
          placeholder="Enter Question"
          style="margin-bottom: 1px"
          :maxlength="max1"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.question"
              >{{ errors.question[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max1 - form.question.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle">
          Question (English)<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.e_sentence"
          class="form-control"
          type="text"
          name="name"
          placeholder="Enter Question"
          style="margin-bottom: 1px"
          :maxlength="max2"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.e_sentence"
              >{{ errors.e_sentence[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max2 - form.e_sentence.length"> </span>
            </div>
          </div>
        </div>

        <div>
          <label class="lablestyle">
            Blank Index<span class="text-danger"> *</span></label
          >

          <select
            @change="selectIndex"
            class="form-control"
            v-model="form.blankIndex"
          >
            <option v-for="(opt, index) in options1" :key="index" :value="opt">
              {{ opt }}
            </option>
          </select>

          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.blankIndex"
            >{{ errors.blankIndex[0] }}</span
          >
          <br />
        </div>


        <div>
          <label class="lablestyle">
            Answer<span class="text-danger"> *</span></label
          >

          <v-select
            v-model="selectOption"
            :options="options"
            placeholder="Select Answer"
            label="options"
            @input="selectId($event)"
          ></v-select>

          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.answer"
            >{{ errors.answer[0] }}</span
          >
          <br />

          <label class="lablestyle"> Turkish Sound File</label>
          <input
            v-if="form.id"
            :v-model="form.t_sound_file"
            type="file"
            @change="GetTurkishName"
            style="display: none"
            id="t_sound_file"
            name="t_sound_file"
            accept="audio/*"
            class="form-control"
            placeholder="Enter Turkish Word"
          />
          <input
            v-else
            :v-model="form.t_sound_file"
            type="file"
            id="t_sound_file"
            name="t_sound_file"
            accept="audio/*"
            class="form-control"
            placeholder="Enter Turkish Word"
          />
          <div class="ButtonForFile" v-if="form.id">
            <input
              class=""
              type="button"
              id="loadFileXml"
              value="Choose File"
              onclick="document.getElementById('t_sound_file').click();"
            /><span class="typeButtonText">
              &nbsp;{{
                form.t_sound_file ? form.t_sound_file : "No file chosen"
              }}</span
            >
          </div>
          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.t_sound_file"
            >{{ errors.t_sound_file[0] }}</span
          >

          <br />

          <label class="lablestyle">
            Image<span class="text-danger"> *</span></label
          >
          <input
            class="form-control"
            :v-model="form.image"
            type="file"
            id="image"
            name="image"
            placeholder="Enter Name"
            style="margin-bottom: 1px"
            accept="image/*"
          />

          <img
            v-if="form.id"
            class="imgg mt-2"
            :src="base_url + img_folder + form.image"
            style=""
            alt=""
          />
          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.image"
            >{{ errors.image[0] }}</span
          >
          <br />
        </div>

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterA from "../../ToasterA";
import ToasterU from "../../ToasterU";
import BackButton from "../../ButtonComponent/BackButton.vue";
export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    BackButton,
  },

  data() {
    return {
      errors: [],
      loader: false,
      blankIndex: "",
      blankIndexNo: "",
      options1: [],
      max1: 100,
      max: 20,
      max2: 100,
      options: [],
      toaster: false,
    
      alert: false,
      level_id: null,
      selectOption: "",
      img_folder: "/images/",
      base_url: this.$appName,
      index: 0,
      activities: null,
      form: new Form({
        id: "",
        name: "",
        image: "",
        answer: "",
        t_sound_file: "",
        blankIndex: "",
        activity_id: "",
        question: "",
        e_sentence: "",
      }),
    };
  },

  mounted() {
    if (this.$route.params.id != null) {
      axios
        .get(this.$appName + `/api/question/show/${this.$route.params.id}`)
        .then((res) => {
          this.form = res.data;

          var selectID = res.data.answer;
          this.selectOption = this.options.find(
            (x) => x.id == selectID
          ).options;


          var stringArray = res.data.question
            .split(/(\s+)/)
            .filter((e) => e.trim().length > 0);
          this.options1 = stringArray;
          this.blankIndexNo = res.data.IndexNo;

        });
    }
  },

  created() {
    this.activityID = this.$route.params.acivitityID;

    axios
      .get(this.$appName + `/api/question/getOption/${this.activityID}`)
      .then((res) => {
        this.options = res.data;
      });
  },

  methods: {
    selectId(e) {
      this.form.answer = e.id;
    },

    selectIndex(event) {
      this.blankIndexNo = event.target.selectedIndex;
    },

    GetTurkishName(event) {
      this.form.t_sound_file = event.target.files[0].name;
    },
    questionSearch(e) {
      this.form.blankIndex = "";
      var stringArray = this.form.question
        .split(/(\s+)/)
        .filter((e) => e.trim().length > 0);
      this.options1 = stringArray;
      this.form.blankIndex = stringArray[0];
      this.blankIndexNo = 0;

    },
    async AddQuestion() {
      this.loader = true;


      let data = new FormData();
      data.append("id", this.form.id);
      data.append("totalOptions", this.totalOptions);
      data.append("answer", this.form.answer);
      data.append("question", this.form.question);
      data.append("activity_id", this.activityID);
      data.append("blankIndex", this.form.blankIndex);
      data.append("IndexNo", this.blankIndexNo);
      data.append("e_sentence", this.form.e_sentence);

      data.append("image", document.getElementById("image").files[0]);
      data.append(
        "t_sound_file",
        document.getElementById("t_sound_file").files[0]
      );

      let addNewActivity = await axios
        .post(this.$appName + "/api/question/store3", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewActivity) {
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;
        setTimeout(() => {
          this.$router.push({
            name: "Add activity3",
            params: { idd: this.$activity4 },
          });
        }, 0);
      }
    },
  },
};
</script>

<style scoped>
</style>
