<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <form
        ref="form"
        @submit.prevent="AddOption"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <b-modal
          id="modall"
          title="Option"
          @cancel="methodClose"
          @close="methodClose"
          @ok="handleOk"
          no-close-on-backdrop
        >
          <base-input
            v-model="this.sublevel_id"
            type="hidden"
            name="sublevel_id"
            placeholder="Enter Name"
            style="margin-bottom: 1px"
          />
          <base-input
            v-model="form.id"
            type="hidden"
            name="id"
            placeholder="Enter Name"
            style="margin-bottom: 1px"
          />
          <input
            type="text"
            id="fileimport"
            name="fileimport"
            class="form-control"
            placeholder="Enter Option"
            v-model="form.optionA"
            style="margin-bottom: 0rem"
          />

          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.optionA"
            >{{ errors.optionA[0] }}</span
          >
        </b-modal>
      </form>

      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <!-- <button
            style="
              border-radius: 26px;
              font-size: 14px;
              background: #1f7dad;
              border-color: #1f7dad;
            "
            v-b-modal.modall
            class="btn btn-primary ml-2 my-2 mr-2 btnst import_utlity"
          >
            Add
          </button> -->

          <base-button
            @click="add"
            type="primary"
            style="
        
              background: #1f7dad;
              border-color: #1f7dad;
            "
            class="my-2 ml-2 mr-2 btnst"
            >Add</base-button
          >
        </div>

        <div
          class="col-md-8 text-right mt-2 mb-2 p-0"
          style="margin-left: -6px"
        >
          <!-- <button
            class="btn btn-dark statusBtn my-2 mr-2 btnst"
            style=""
            @click="activate"
            id="activate"
          >
            Active
          </button>
          <button
            class="btn btn-secondary statusBtn my-2 mr-2 btnst"
            @click="deactivate"
            id="deactivate"
          >
            Deactive
          </button> -->

          <button
            class="btn btn-danger statusBtn my-2 mr-2 btnst"
            style="border-radius: 26px"
            @click="deletee"
            id="delete"
          >
            Delete
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h2 style="font-size: 1.5rem; margin-bottom: -11px">
            Activity ({{ levelLenght }})
          </h2>
        </div>
      </div>

      <br />
      <br />
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />

            <b-table
              :items="levels"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>

              <template v-slot:cell(id)="levels">
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="levels.item.id"
                  name="id[]"
                  number
                />
              </template>
              <template v-slot:cell(total)="levels">
                <span> {{ levels.item.option.length }}</span>
              </template>

              <template v-slot:cell(sub_level)="levels">
                <router-link
                  :to="{
                    name: 'View sub level',
                    params: { id: levels.item.id },
                  }"
                >
                  <span style="color: black">{{
                    levels.item.sub_level.length
                  }}</span>
                </router-link>
              </template>
              <template v-slot:cell(status)="levels">
                <b-form-checkbox
                  @click.native="singleAct(levels.item.id)"
                  v-if="levels.item.status == 'activate'"
                  name="check-button"
                  switch
                  checked="true"
                >
                </b-form-checkbox>

                <b-form-checkbox
                  @click.native="singleDct(levels.item.id)"
                  v-else
                  name="check-button"
                  switch
                  checked="false"
                >
                </b-form-checkbox>
              </template>

              <template v-slot:cell(action)="levels">
                <router-link
                  :to="{
                    name: 'Edit activity3',
                    params: { id: levels.item.id },
                  }"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-pencil"
                  />
                </router-link>

                <!-- <a
                  @click="editOption(levels.item.id)"
                  style="font-size: 16px; color: #1f7dad"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-pencil"
                  />
                </a> -->
                <a
                  @click="deleteLevel(levels.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterAc from "../../ToasterAc";
import ToasterDe from "../../ToasterDe";
import ToasterD from "../../ToasterD";

export default {
  components: {
    Loader1,
    ToasterDe,
    ToasterAc,
    ToasterD,
  },
  data() {
    return {
      levels: [],
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      toasterac: false,
      toasterde: false,
      toasterd: false,
      toaster: false,
      rows: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,
      imgUrl: this.$appName,
      sublevel_id: "",

      form: new Form({
        status: "",
        id: "",

        optionA: "",
        sublevel_id: "",
      }),
      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "name", label: "Name", tdClass: "align-middle" },
        // { key: "total", label: "Total Options", tdClass: "align-middle" },
        // { key: "status", label: "Status", tdClass: "align-middle" },

        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {
    this.getAllActivity();
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },
    getAllActivity() {
      this.sublevel_id = this.$route.params.id;
      axios
        .get(this.$appName + `/api/activity/Activity/${this.$route.params.id}`)
        .then((res) => {
          this.levels = res.data;
          this.levelLenght = res.data.length;
          this.rows = res.data.length;
        });
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.AddOption();
    },

    methodClose() {
      location.reload();
    },
    async AddOption() {
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("optionA", this.form.optionA);
      data.append("sublevel_id", this.sublevel_id);
      let addNewLevel = await axios
        .post(this.$appName + "/api/option/Option", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewLevel) {
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;
        setTimeout(() => {
          this.$router.push({
            name: "View option",
            params: { id: this.sublevel_id },
          });
        }, 0);
      }
    },
    add() {
      // localStorage.removeItem("level_id");
      localStorage.setItem("subLevelID", this.$route.params.id);
      this.$router.push({ path: "/activity/add/3" });
    },

    editOption(id) {
      axios.get(this.$appName + `/api/option/Option/${id}`).then((res) => {
        console.log(res.data);
        this.form = res.data;
        this.sublevel_id = res.data.sublevel_id;
      });
      this.$bvModal.show("modall");
    },
    deleteLevel(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          axios
            .delete(this.$appName + `/api/activity/Activity/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.levels.map((data) => data.id).indexOf(id);
              this.levels.splice(i, 1);
              this.isDelete = false;
              // location.reload();
            });
        }
      });
    },
    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;
        this.count = this.levels.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },

    async activate() {
      this.status = "activate";
      this.changeStatus();
    },

    async deactivate() {
      this.status = "deactivate";
      this.changeStatus();
    },

    async deletee() {
      this.status = "delete";
      this.changeStatus();
    },

    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);

        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;

            axios
              .post(this.$appName + "/api/activity/statusChange", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;
                this.toasterd = true;
                this.getAllActivity();
                // location.reload();
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },
  },
};
</script>

<style>
.btnst {
  border-radius: 26px;
  font-size: 15px;
}
</style>
