<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />

       <BackButton />

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Level</h2>
        <h2 v-else style="font-size: 1.5rem">Add Level</h2>
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="AddLevel"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <label class="lablestyle">
          Level Name<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />
        <input
          class="form-control"
          v-model="form.name"
          type="text"
          name="name"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
          :maxlength="max"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.name"
              >{{ errors.name[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max - form.name.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle">
          Level Description<span class="text-danger"> *</span></label
        >

        <b-form-textarea
          id="textarea"
          v-model="form.description"
          description="description"
          class="form-control"
          placeholder="Enter Description"
          rows="4"
          max-rows="10"
          :maxlength="max1"
        ></b-form-textarea>

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.description"
              >{{ errors.description[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="showLength">
              <span>Characters </span>
              <span v-text="max1 - form.description.length"> </span>
            </div>
          </div>
        </div>

        <br />

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import BackButton from "../ButtonComponent/BackButton.vue";
export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
        BackButton,
  },

  data() {
    return {
      errors: [],
      loader: false,
      toaster: false,
      toasterprop: false,
      max: 10,
      max1: 150,
      alert: false,
      form: new Form({
        id: "",
        name: "",
        description: "",
      }),
    };
  },

  created() {
    // console.log(this.$appName + `/api/edit/${this.$route.params.id}`);
    if (this.$route.params.id != undefined) {
      axios
        .get(this.$appName + `/api/level/edit/${this.$route.params.id}`)
        .then((res) => {
          console.log(res.data.name);
          this.form = res.data;
        });
    }
  },

  methods: {
    async AddLevel() {
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("name", this.form.name);
      data.append("description", this.form.description);

      let addNewLevel = await axios
        .post(this.$appName + "/api/level/Level", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewLevel) {
        console.log("Sdsdf");
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;
        setTimeout(() => {
          this.$router.push({ path: "/level" });
        }, 0);
      }
    },
  },
};
</script>

<style></style>
