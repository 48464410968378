<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
         <div class="col-12" style="padding-left: 0px; padding-right: 7px">
      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <router-link to="/activity/word-opposite/add">
            <base-button
              type="primary"
              style="background: #1f7dad; border-color: #1f7dad"
              class="my-2 ml-2 btnst"
              >Add</base-button
            >
          </router-link>
        </div>


        <DeleteButton @bulkActions="bulkActions" />
      </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4 style="font-size: 1.5rem; margin-bottom: -11px">
            Words({{ levelLenght }})
          </h4>
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />

            <b-table
              :items="category"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>

              <template v-slot:cell(id)="category">
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="category.item.id"
                  name="id[]"
                  number
                />
              </template>

                  <template v-slot:cell(word)="category">
            {{category.item.word.tname}}
              </template>
              <template v-slot:cell(opposit_word)="category">
            {{category.item.opposit_word.tname}}
              </template>

              <template v-slot:cell(image)="category">
                <img
                  class="imgg"
                  :src="imgUrl + '/images/' + category.item.image"
                  alt="..."
                />
              </template>

              <template v-slot:cell(action)="category">
                <router-link
                  :to="{
                    name: 'Edit oppositeWord',
                    params: { id: category.item.id },
                  }"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-pencil"
                  />
                </router-link>
                <a
                  @click="deleteCategory(category.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
// import "bootstrap/dist/css/bootstrap.min.css";
// import "datatables.net-bs4";
// import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterAc from "../../ToasterAc";
import ToasterDe from "../../ToasterDe";
import ToasterD from "../../ToasterD";
import DeleteButton from "../../ButtonComponent/DeleteButton.vue";
export default {
  components: {
    Loader1,
    ToasterDe,
    ToasterAc,
    ToasterD,
    DeleteButton,
  },
  data() {
    return {
      category: [],
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      toasterac: false,
      toasterde: false,
      toasterd: false,
      rows: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,
      imgUrl: this.$appName,
      form: new Form({
        status: "",
        id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "word", label: "Word", tdClass: "align-middle" },
        { key: "opposit_word", label: "OppositWord", tdClass: "align-middle" },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {
    this.getAllCategory();
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCategory() {
      axios.get(this.$appName + `/api/activity/getAllWords`).then((res) => {
        //pushing data to items that will show inside table
        this.category = res.data;
        console.log('ds',this.category);
        this.levelLenght = res.data.length;
        this.rows = res.data.length;
      });
    },
    deleteCategory(id) {
      // if (response.data == 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          axios
            .delete(this.$appName + `/api/activity/oppositeDelete/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.category.map((data) => data.id).indexOf(id);
              this.category.splice(i, 1);
              this.loader = false;

              this.isDelete = false;
              this.levelLenght = this.category.length;
              this.rows = this.category.length;
            });
        }
      });
    },

    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;

        this.count = this.category.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },

    bulkActions(type) {
      if (type == "delete") {
        this.status = "delete";
      }
      this.changeStatus();
    },

    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, change it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;

            axios
              .post(this.$appName + "/api/activity/statusChange1", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;
                if (this.status == "deactivate") {
                  this.toasterde = true;
                } else {
                  this.toasterac = true;
                }
                this.getAllCategory();
                this.allSelected = false;
                // location.reload();
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitUtlity();
    },

    methodClose() {
      location.reload();
    },
  },
};
</script>
