<template>
  <div></div>
</template>

<script>
export default {
  name: "ToasterA",
  
  mounted() {    
    this.$toast.open({
      message: "Record added successfully !",
      type: "success",
      position: "top-right",
      dismissible: true,
    });
  },







};
</script>
