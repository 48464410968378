var globalFunction = {
  secondsToDhms(seconds) {
    seconds = Number(seconds);
    var numyears = Math.floor(seconds / 31536000);
    var nummonths = Math.floor((seconds % 31536000) / 2628000);
    var numdays = Math.floor(((seconds % 31536000) % 2628000) / 86400);
    var numhours = Math.floor(
      (((seconds % 31536000) % 2628000) % 86400) / 3600
    );
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
                                  
    var numyears1 =
      numyears > 0 ? numyears + (numyears == 1 ? " y, " : " y's, ") : "";
    var nummonths1 =
      nummonths > 0 ? nummonths + (nummonths == 1 ? " mon, " : " mon's, ") : "";
    var numdays1 =
      numdays > 0 ? numdays + (numdays == 1 ? " day, " : " days, ") : "";
    var numhours1 =
      numhours > 0 ? numhours + (numhours == 1 ? " hr, " : " hr's, ") : "";
    var numminutes1 =
      numminutes > 0 ? numminutes + (numminutes == 1 ? " min" : " min's") : "";
    return numyears1 + nummonths1 + numdays1 + numhours1 + numminutes1;
    // this.totalTime = mDisplay;
    // console.log("dhm", dDisplay, hDisplay, mDisplay);
  },
};

export default globalFunction;
