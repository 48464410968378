<template>
    <div>
      <aplayer
        v-if="soundUrl"
        :lrcType="3"
        controls
        :music="{
          src: imgUrl + '/images/' + soundUrl,
          pic: 'https://moeplayer.b0.upaiyun.com/aplayer/secretbase.jpg',
        }"
      />
      <aplayer
        class="greenPlayer"
        v-else
        :music="{
          // pic: 'https://moeplayer.b0.upaiyun.com/aplayer/secretbase.jpg',
        }"
      />
    </div>
    <!-- <mini-audio  :audio-source="imgUrl + '/images/' + soundUrl"> </mini-audio>
    </div>  -->
  </template>
  
  <script>
  import Aplayer from "vue-aplayer";
  
  export default {
    components: {
      Aplayer,
    },
    data() {
      return {
        //   ImageSlideshow: false,
      };
    },
    name: "EnglishSoundTag",
    props: ["imgUrl", "soundUrl"],
    methods: {},
  };
  </script>
  