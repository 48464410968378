<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />

      <ImageSlideshow
        v-if="sildeShowFlag"
        @sildeImageClose="sildeImageClose"
        :getImages="getImages"
        :index="index"
      />

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Activity</h2>
        <h2 v-else style="font-size: 1.5rem">Add Activity</h2>
      </div>

      <div class="alert alert-danger" v-if="errorC">
        <span
          class="text-danger"
          style="font-size: 13px; color: white !important"
          v-if="errorC"
        >
          {{ "Minimum two options are required." }}</span
        >
      </div>

      <div class="alert alert-danger" v-if="errorR">
        <span
          class="text-danger"
          style="font-size: 13px; color: white !important"
          v-if="errorR"
        >
          {{ "The options name field is required." }}</span
        >
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="AddActivity"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <div class="col-12 text-center mb-1">
          <div class="row">
            <div class="col-6">
              <h3 class="levelC">Level:</h3>
              {{ this.level_id }}
              <span
                class="text-danger"
                style="font-size: 13px"
                v-if="errors.level_id"
                >{{ errors.level_id[0] }}</span
              >
            </div>
            <div class="col-6">
              <h3 class="levelC">SubLevel:</h3>

              {{ this.sublevel_name }}
              <span
                class="text-danger"
                style="font-size: 13px"
                v-if="errors.sublevel_id"
                >{{ errors.sublevel_id[0] }}</span
              >
            </div>
          </div>
        </div>

        <input
          v-model="level_id"
          class="form-control"
          type="hidden"
          readonly
          name="name"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />
        <input
          v-model="sublevel_name"
          class="form-control"
          type="hidden"
          readonly
          value="2.1.1"
          name="name"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <label class="lablestyle">
          Name<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.name"
          readonly
          class="form-control"
          type="text"
          name="name"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />
        <span class="text-danger" style="font-size: 13px" v-if="errors.name">{{
          errors.name[0]
        }}</span>
        <br />

        <div>
          <div class="form-group mb-2" v-for="(input, k) in inputs" :key="k">
            <label class="lablestyle">
              Options<span class="text-danger"> *</span></label
            >

            <div class="text-right">
              <div class="input-group">
                <input
                  type="text"
                  style="
                    border-right: 1px solid rgb(206, 212, 218);
                    border-radius: 0.375rem;
                  "
                  placeholder="Enter Option"
                  class="form-control"
                  v-model="input.options"
                />
                <div class="input-group-append">
                  <span class="input-group-btn">
                    <button
                      style="background: #dc2c30"
                      type="button"
                      class="btn btn-danger btn-number appendBtn"
                      @click="remove(k)"
                      v-show="k || (!k && inputs.length > 1)"
                    >
                      <span class="fas fa-minus"></span>
                    </button>
                  </span>
                </div>
              </div>

              <div class="text-left">
                <span
                  class="text-danger"
                  style="font-size: 13px"
                  v-if="errors.option"
                >
                  {{ errors.option[0] }}</span
                >
              </div>

              <span class="input-group-btn">
                <button
                  style=""
                  type="button"
                  class="btn btn-default btn-number appendplus mt-2"
                  @click="add(k)"
                  v-show="k == inputs.length - 1 && k < 1"
                >
                  <span class="fas fa-plus"></span>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-4 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>

      <div class="" v-if="form.id">
        <hr />
        <div class="row">
          <div class="col-12 text-right p-0"></div>
        </div>
        <div class="col-12" style="padding-left: 7px; padding-right: 7px">
          <div class="row">
            <div class="col-md-4 mb-2 mt-2 p-0">
              <router-link
                :to="{
                  name: 'Question add2',
                  params: { acivitityID: this.$route.params.id },
                }"
              >
                <AddButton />
              </router-link>
            </div>

            <div class="col-md-8 text-right mt-2 mb-2 p-0">
              <button
                class="btn btn-danger statusBtn my-2 mr-2 btnst"
                style="border-radius: 26px"
                @click="deletee"
                id="delete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <h2 style="font-size: 1.5rem">Questions ({{ levelLenght }})</h2>
        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 col-md-6 p-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-6 float-right p-0"
              style="margin-top: 3px"
            >
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right float-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="table-responsive">
              <form
                method="post"
                @submit.prevent="changeStatus"
                @keydown="form.onKeydown($event)"
                enctype="multipart/form-data"
              >
                <input type="hidden" v-model="status" name="status" id="" />
                <b-table
                  :items="category"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  ref="selectableTable"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  empty-text="No record found"
                  empty-filtered-text="No search result found"
                  @filtered="onFiltered"
                >
                  <template #head(id)>
                    <input
                      type="checkbox"
                      v-model="allSelected"
                      @change="selectAll"
                    />
                  </template>

                  <template v-slot:cell(id)="category">
                    <input
                      type="checkbox"
                      v-model="selected"
                      :value="category.item.id"
                      name="id[]"
                      number
                    />
                  </template>
                  <template v-slot:cell(answer)="category">
                    <span v-if="category.item.option_answer != null">
                      {{ category.item.option_answer.options }}</span
                    >
                  </template>

                  <template v-slot:cell(image)="category">
                    <img
                      @click="indexImage(category.item.id)"
                      v-if="category.item.image != null"
                      style="width: 50px !important"
                      class="imgg"
                      :src="imgUrl + '/images/' + category.item.image"
                    />
                    <img
                      v-else
                      @click="indexImage('static')"
                      style="width: 50px !important"
                      class="imgg"
                      :src="imgUrl + '/images/defaultYMT.png'"
                    />
                  </template>

                  <template v-slot:cell(t_sound_file)="category">
                    <TurkishSoundTag
                      :imgUrl="imgUrl"
                      :soundUrl="category.item.t_sound_file"
                    />
                  </template>
                  <template v-slot:cell(action)="category">
                    <router-link
                      :to="{
                        name: 'Edit question2',
                        params: {
                          id: category.item.id,
                          acivitityID: $route.params.id,
                        },
                      }"
                    >
                      <font-awesome-icon
                        style="font-size: 16px; padding: 0 4px"
                        icon="fa-solid fa-pencil"
                      />
                    </router-link>
                    <a
                      @click="deleteQuestion(category.item.id)"
                      style="font-size: 16px; color: #dc3545"
                    >
                      <font-awesome-icon
                        style="font-size: 16px; padding: 0 4px"
                        icon="fa-solid fa-trash-can"
                      />
                    </a>
                  </template>
                </b-table>
              </form>

              <br />
              <div class="col-sm-12 col-md-6 float-right p-0" style="">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right mb-3"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import Swal from "sweetalert2";
import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import AddButton from "../ButtonComponent/AddButton.vue";
import ImageSlideshow from "../Components/ImageSlideshow.vue";
import TurkishSoundTag from "../Components/TurkishSoundTag.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    ToasterDe,
    ToasterAc,
    ToasterD,
    AddButton,
    TurkishSoundTag,
    ImageSlideshow,
  },

  data() {
    return {
      inputs: [
        {
          options: "",
        },
      ],
      errors: [],
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      sildeShowFlag: false,
      TurkishSoundTag,
      ImageSlideshow,
      alertDuplicate: "",
      toasterac: false,
      toasterde: false,
      toasterd: false,
      rows: "",

      index: null,
      getImages: [],
      errorC: false,
      errorR: false,

      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,

      imgUrl: this.$appName,

      toaster: false,
      toasterprop: false,
      answer: "",
      optionName: "",
      optionID: "",
      sublevel_id: "",
      level_id: "",
      sublevel_name: "",
      img_folder: "/images/",
      base_url: this.$appName,
      category: [],
      subLevelName: "",
      form: new Form({
        id: "",
        optionID: "",
        image: "",
        name: "2.4.2",
        answer: "",
        status: "",
        option: "",
        sublevel_id: "",
        level_id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "image", label: "Image", tdClass: "align-middle" },
        { key: "question", label: "Question", tdClass: "align-middle" },
        // { key: "name", label: "Name", tdClass: "align-middle" },
        { key: "answer", label: "Answer", tdClass: "align-middle" },
        {
          key: "t_sound_file",
          label: "Turkish Sound File",
          tdClass: "align-middle",
        },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  created() {
    axios
      .get(this.$appName + `/api/activity/edit1/${this.$route.params.idd}`)
      .then((res) => {
        console.log("dd", res.data.id);
        if (res.data.id != undefined) {
          this.form = res.data;
          this.$route.params.id = res.data.id;
          this.inputs = res.data.option;
          this.getActivity();
        }
      });
  },

  mounted() {
    axios.get(this.$appName + `/api/activity/fourthSubLevel`).then((res) => {
      this.level_id = res.data.level.name;
      this.sublevel_id = res.data.id;
      this.sublevel_name = res.data.name;
    });
  },

  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },
    getActivity() {
      axios
        .get(this.$appName + `/api/question/Question/${this.$route.params.id}`)
        .then((res) => {
          this.category = res.data;
          this.levelLenght = res.data.length;
          this.rows = res.data.length;

          this.getImages = this.category.map((item) => {
            return {
              id: item.id,
              name: this.imgUrl + "/images/" + item.image,
            };
          });
          if (this.getImages.length > 0) {
            this.getImages = Object.assign(
              ...this.getImages.map(({ id, name }) => ({ [id]: name }))
            );
          }
        });
    },
    selectId(e) {
      this.form.answer = e.optionA;
    },

    add(index) {
      this.inputs.push({ options: "" });
    },
    remove(index) {
      this.inputs.splice(index, 1);
    },
    async AddActivity() {
      this.optionName = this.inputs.map((data) => data.options);
      this.optionID = this.inputs.map((data) => data.id);
      this.loader = true;

      if (this.level_id == undefined) {
        this.level_id = "";
      }
      if (this.optionID[0] == undefined) {
        this.optionID = "";
      }
      if (
        this.optionName == "," ||
        this.optionName == ",," ||
        this.optionName == ",,,"
      ) {
        this.optionName = "";
      }
      var res = this.optionName.filter((val) => val == "");

      let data = new FormData();
      data.append("id", this.form.id);
      data.append("name", this.form.name);
      data.append("option", this.optionName);

      data.append("sublevel_id", this.sublevel_id);
      data.append("level_id", this.level_id);
      data.append("optionID", this.optionID);

      if (this.optionName.length != 2 || res.length > 0) {
        if (res.length > 0) {
          this.errorR = true;
          this.errorC = false;
        } else {
          this.errorC = true;
          this.errorR = false;
        }
        this.loader = false;
      } else {
        let addNewActivity = await axios
          .post(this.$appName + "/api/activity/Activity", data)
          .catch((error) => {
            this.loader = false;
            this.errors = error.response.data.errors;
          });
        if (addNewActivity) {
          this.toaster = true;
          this.toasterprop = true;
          this.loader = false;

          setTimeout(() => {
            location.reload();
          }, 0);
        }
      }
    },

    async deletee() {
      this.changeStatus();
    },
    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);

        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;

            axios
              .post(this.$appName + "/api/question/deleteQue", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;
                this.toasterd = true;

                // location.reload();
                this.getActivity();
                this.allSelected = false;
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },

    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;
        this.count = this.category.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },
    indexImage(key) {
      this.index = key;
      this.sildeShowFlag = true;
    },
    sildeImageClose(value) {
      this.sildeShowFlag = value;
      this.index = null;
    },
    deleteQuestion(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          axios
            .delete(this.$appName + `/api/question/Question/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.category.map((data) => data.id).indexOf(id);
              this.category.splice(i, 1);
              this.loader = false;
              this.isDelete = false;
              this.levelLenght = this.category.length;
              this.rows = this.category.length;
            });
        }
      });
    },
  },
};
</script>

<style></style>
