<template>
  <div></div>
</template>

<script>
export default {
  name: "ToasterU",
  mounted() {
    this.$toast.open({
      message: "Record updated successfully !",
      type: "success",
      position: "top-right",
      dismissible: true,
    });
  },
};
</script>
