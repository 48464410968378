import { render, staticRenderFns } from "./View1.vue?vue&type=template&id=357619dc&"
import script from "./View1.vue?vue&type=script&lang=js&"
export * from "./View1.vue?vue&type=script&lang=js&"
import style0 from "./View1.vue?vue&type=style&index=0&id=357619dc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports