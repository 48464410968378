<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <ImageSlideshow
        v-if="sildeShowFlag"
        @sildeImageClose="sildeImageClose"
        :getImages="getImages"
        :index="index"
      />

      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <router-link to="/category/add/">
            <AddButton />
          </router-link>
        </div>

        <BulkAction @bulkActions="bulkActions" />
      </div>
      <div class="row">
        <div class="col-12">
          <h4 style="font-size: 1.5rem; margin-bottom: -11px">
            Categories({{ levelLenght }})
          </h4>
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />

            <b-table
              :items="category"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>

              <template v-slot:cell(status)="category">
                <b-form-checkbox
                  @click.native="singleAct(category.item.id)"
                  v-if="category.item.status == 'activate'"
                  name="check-button"
                  switch
                  checked="true"
                >
                </b-form-checkbox>

                <b-form-checkbox
                  @click.native="singleDct(category.item.id)"
                  v-else
                  name="check-button"
                  switch
                  checked="false"
                >
                </b-form-checkbox>
              </template>
              <template v-slot:cell(id)="category">
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="category.item.id"
                  name="id[]"
                  number
                />
              </template>

              <template v-slot:cell(image)="category">
                <img
                  @click="indexImage(category.item.id)"
                  v-if="category.item.image != null"
                  class="imgg"
                  :src="imgUrl + '/images/' + category.item.image"
                />

                <img
                  v-else
                  @click="indexImage('static')"
                  style="width: 50px !important"
                  class="imgg"
                  :src="imgUrl + '/images/defaultYMT.png'"
                />
              </template>

              <template v-slot:cell(action)="category">
                <router-link
                  :to="{
                    name: 'Edit category',
                    params: { id: category.item.id },
                  }"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-pencil"
                  />
                </router-link>
                <a
                  @click="deleteCategory(category.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
// import "bootstrap/dist/css/bootstrap.min.css";
// import "datatables.net-bs4";
// import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import AddButton from "../ButtonComponent/AddButton.vue";
import BulkAction from "../ButtonComponent/BulkAction.vue";
import ImageSlideshow from "../Components/ImageSlideshow.vue";

export default {
  components: {
    Loader1,
    ToasterDe,
    ToasterAc,
    ToasterD,
    AddButton,
    BulkAction,
    ImageSlideshow,
  },
  data() {
    return {
      category: [],
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      toasterac: false,
      toasterde: false,
      toasterd: false,
      rows: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      getImages: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      sildeShowFlag: false,
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,
      index: null,
      imgUrl: this.$appName,
      form: new Form({
        status: "",
        id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "image", label: "Image", tdClass: "align-middle" },
        { key: "name", label: "Name", tdClass: "align-middle" },
        { key: "status", label: "Status", tdClass: "align-middle" },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {
    this.getAllCategory();
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCategory() {
      axios.get(this.$appName + `/api/category/Category`).then((res) => {
        //pushing data to items that will show inside table
        this.category = res.data;
        this.getImages = this.category.map((item) => {
          return {
            id: item.id,
            name: this.imgUrl + "/images/" + item.image,
          };
        });

        if (this.getImages.length > 0) {
          this.getImages = Object.assign(
            ...this.getImages.map(({ id, name }) => ({ [id]: name }))
          );
        }

        this.levelLenght = res.data.length;
        this.rows = res.data.length;
      });
    },
    deleteCategory(id) {
      // if (response.data == 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");

          axios
            .delete(this.$appName + `/api/category/Category/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.category.map((data) => data.id).indexOf(id);
              this.category.splice(i, 1);
              this.loader = false;
              this.isDelete = false;
              this.levelLenght = this.category.length;
              this.rows = this.category.length;
            });
        }
      });
    },

    singleAct(id) {
      this.loader = true;
      axios
        .post(this.$appName + `/api/category/changeDeactivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);

          // location.reload();
        })
        .catch((error) => {
          this.loader = false;

          console.log(error);
        });
    },
    singleDct(id) {
      this.loader = true;
      axios
        .post(this.$appName + `/api/category/changeActivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);

          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;

        this.count = this.category.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },

    bulkActions(type) {
      if (type == "delete") {
        this.status = "delete";
      } else if (type == "activate") {
        this.status = "activate";
      } else {
        this.status = "deactivate";
      }
      this.changeStatus();
    },
    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);
        if (this.status != "delete") {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;
              axios
                .post(this.$appName + "/api/category/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  if (this.status == "deactivate") {
                    this.toasterde = true;
                  } else {
                    this.toasterac = true;
                  }
                  this.getAllCategory();
                  this.allSelected = false;
                  this.selected = [];

                  // location.reload();
                });
            }
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;
              axios
                .post(this.$appName + "/api/category/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  this.toasterd = true;

                  this.getAllCategory();
                  this.selected = [];
                  this.allSelected = false;
                });
            }
          });
        }
      } else {
        alert("Please select atleast one record");
      }
    },
    indexImage(key) {
      this.index = key;
      this.sildeShowFlag = true;
    },

    sildeImageClose(value) {
      this.sildeShowFlag = value;
      this.index = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitUtlity();
    },

    methodClose() {
      location.reload();
    },
  },
};
</script>
