<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <!-- <div>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          style="
            background: #1f7dad;
            border-color: #1f7dad;
            border-radius: 26px;
            font-size: 13px;
            padding: 6px 11px 6px 11px;
          "
          @click="$router.go(-1)"
        >
          Back
        </button>
      </div> -->
      <BackButton />

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit User</h2>
        <h2 v-else style="font-size: 1.5rem">Add User</h2>
      </div>
      <hr />
      <form
        @submit.prevent="AddUser"
        method="post"
        enctype="multipart/form-data"
      >
        <base-input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <div v-if="error1" class="alert alert-danger">
          {{ this.errors }}
        </div>

        <base-input
          v-model="this.level_id"
          type="hidden"
          name="lavel_id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <label class="lablestyle">
          Name<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.name"
          type="text"
          pattern="^[a-zA-Z0-9\s-]+$"
          name="name"
          class="form-control"
          required
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <br />

        <label class="lablestyle">
          Nick<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.nick"
          type="text"
          pattern="^[a-zA-Z0-9\s-]+$"
          name="nic"
          class="form-control"
          required
          placeholder="Enter Nick"
          style="margin-bottom: 1px"
        />

        <br />

        <label class="lablestyle">
          Email<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          type="email"
          name="email"
          class="form-control"
          required
          placeholder="Enter Email"
          style="margin-bottom: 1px"
        />

        <br />

        <label class="lablestyle">
          Password<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.password"
          type="password"
          pattern="^\S*$"
          minlength="6"
          class="form-control"
          name="password"
          placeholder="Enter Password"
          style="margin-bottom: 1px"
        />

        <br />
        <label class="lablestyle">
          Phone<span class="text-danger"> *</span></label
        >

        <input
          disabled
          v-model="form.phone"
          maxlength="11"
          type="number"
          required
          class="form-control"
          name="phone"
          placeholder="Enter Phone"
          style="margin-bottom: 1px"
        />
        <br />

        <label class="lablestyle">
          Gender<span class="text-danger"> *</span></label
        >

        <select
          v-model="form.gender"
          options="gender"
          class="form-control"
          placeholder="Select Gender"
          label="name"
          required
        >
          <option selected value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.gender"
          >{{ errors.gender[0] }}</span
        >
        <br />

        <!-- <label class="lablestyle">
          Age<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.age"
          type="number"
          required
          min="1"
          name="age"
          class="form-control"
          placeholder="Enter Age"
          style="margin-bottom: 1px"
        />

        <br /> -->

        <label class="lablestyle">
          Age<span class="text-danger"> *</span></label
        >

        <select
          v-model="form.age"
          options="gender"
          class="form-control"
          placeholder="Select Gender"
          label="name"
          required
        >
          <option selected value="05-10">05 - 10years</option>
          <option selected value="10-20">10 - 20years</option>
          <option selected value="20-30">20 - 30years</option>
          <option selected value="30+">30+ years</option>
        </select>

        <br />
        <label class="lablestyle">
          Reason for learning<span class="text-danger"> *</span></label
        >

        <select
          v-model="form.learning_reason"
          options="gender"
          class="form-control"
          placeholder="Select Gender"
          label="name"
          required
        >
          <option selected value="Job">Job</option>
          <option selected value="Tourism">Tourism</option>
          <option selected value="Interest">Interest</option>
          <option selected value="Other">Other</option>
        </select>

        <br />
        <label class="lablestyle"> Image</label>
        <input
          style="margin-bottom: 1px"
          :v-model="form.image"
          type="file"
          class="form-control"
          name="image"
          id="image"
          placeholder=""
          accept="image/*"
        />

        <span class="text-danger" style="font-size: 13px" v-if="errors.image">{{
          errors.image[0]
        }}</span>
        <br />
        <img
          v-if="form.image"
          class="imgg"
          :src="base_url + img_folder + form.image"
          style="margin-top: -15px"
          alt=""
        />

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import BackButton from "../ButtonComponent/BackButton.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    BackButton,
    ToasterU,
  },

  data() {
    return {
      gender: ["male", "female"],
      errors: [],
      loader: false,
      alert: false,
      level_id: null,
      toaster: false,
      error1: false,
      activities: null,
      base_url: this.$appName,
      img_folder: "/images/",
      form: new Form({
        id: "",
        name: "",
        nick: "",
        email: "",
        password: "",
        gender: "",
        phone: "",
        age: "",
        image: "",

        learning_reason: "",
      }),
    };
  },

  created() {
    // console.log(this.$appName + `/api/edit/${this.$route.params.id}`);
    if (this.$route.params.id != null) {
      axios
        .get(this.$appName + `/api/user/edit/${this.$route.params.id}`)
        .then((res) => {
          console.log(res.data.name);
          this.form = res.data;
        });
    }
  },

  mounted() {
    this.level_id = localStorage.getItem("level_id");

    // alert(level_id)
  },

  methods: {
    AddUser(evt) {
      evt.preventDefault();
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("name", this.form.name);
      data.append("nick", this.form.nick);
      data.append("email", this.form.email);
      data.append("password", this.form.password);
      data.append("age", this.form.age);
      data.append("gender", this.form.gender);
      data.append("learning_reason", this.form.learning_reason);
      data.append("phone", this.form.phone);
      data.append("image", document.getElementById("image").files[0]);

      axios
        .post(this.$appName + "/api/user/register", data)
        .then((response) => {
          this.loader = false;
          if (
            response.data.message ==
            "This account is already exists with this email"
          ) {
            this.error1 = true;
            this.errors = response.data.message;
            console.log(response.data.message);
          } else if (
            response.data.message == "The email has already been taken."
          ) {
            this.error1 = true;
            this.errors = response.data.message;
          } else {
            this.toaster = true;
            setTimeout(() => {
              this.$router.push({ path: "/user" });
            }, 0);
          }
        })
        .catch((error) => {
          this.loader = false;
          this.error1 = true;
        });
    },
  },
};
</script>

<style>
.lablestyle {
  color: #525f7f;
  font-size: 0.935rem;
  font-weight: 600;
}
.form-control {
  padding: 5px 10px !important;
  height: calc(1.5em + 1.01rem + 2px) !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.vs__dropdown-menu {
  /* height: 150px !important; */
  font-size: 0.875rem !important;
}
.vs__search {
  padding: 5px 10px !important;
  color: #8898aa;
  font-size: 0.875rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
}
.vs__selected {
  color: #8898aa;
}
.vs__selected:focus {
  color: #495057;
}

.vs__search:focus {
  color: #495057;

  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.vs--single .vs__selected {
  font-size: 0.875rem !important;
}

.loader-circular {
  width: 120px !important;
  height: 120px !important;
}
#textarea {
  height: auto !important;
}

a.h4.mb-0.text-white.text-uppercase.d-none.d-lg-inline-block.active.router-link-active {
  display: none !important;
}
.vs__selected {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #5b6269;
}
</style>
