<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU />
    </div>
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />

      <BackButton />

      <div class="col-12 p-0">
        <div class="row">
          <div class="col-6">
            <div class="mt-2">
              <h2 style="font-size: 1.5rem">Edit Word</h2>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <form method="post" @submit.prevent="updateWord" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
        <base-input v-model="word.id" type="hidden" name="id" style="" />

        <label class="lablestyle">Select Character<span class="text-danger"> *</span></label>

        <v-select v-model="charName" :options="characters" label="name" :value="word.character_id"
          @input="selectId($event)">
        </v-select>

        <!-- <select v-model="word.character_id" id="" class="form-control">
          <option :value="char.id" v-for="char in characters" :key="char.id">
            {{ char.name }}
          </option>
        </select> -->

        <span class="text-danger" style="font-size: 13px" v-if="errors.character">{{ errors.character[0] }}</span>
        <br />

        <label class="lablestyle">
          Turkish Word<span class="text-danger"> *</span></label>

        <base-input v-model="word.tname" type="text" name="tname" style="margin-bottom: 1px" :maxlength="max"
          placeholder="Enter Turkish Word" />
        <!-- 
        <span class="text-danger" style="font-size: 13px" v-if="errors.tname">{{
          errors.tname[0]
        }}</span> -->

        <div class="row">
          <div class="col-8">
            <span class="text-danger" style="font-size: 13px" v-if="errors.tname">{{ errors.tname[0] }}</span>
          </div>
          <div class="col-4">
            <div class="showLength">
              <span>Characters </span>
              <span v-text="max - word.tname.length"> </span>
            </div>
          </div>
        </div>

        <!-- <br /> -->

        <label class="lablestyle">
          English Word<span class="text-danger"> *</span></label>

        <base-input v-model="word.ename" type="text" name="ename" style="margin-bottom: 1px"
          placeholder="Enter English Word" :maxlength="max1" />

        <div class="row">
          <div class="col-8">
            <span class="text-danger" style="font-size: 13px" v-if="errors.ename">{{ errors.ename[0] }}</span>
          </div>
          <div class="col-4">
            <div class="showLength">
              <span>Characters </span>
              <span v-text="max1 - word.ename.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle">Turkish Sound File</label>
        <br />
        <div class="ButtonForFile">
          <input class="" type="button" id="loadFileXml" value="Choose File"
            onclick="document.getElementById('t_sound_file').click();" /><span class="typeButtonText">
            {{
            word.t_sound_file ? word.t_sound_file : "No file chosen"
            }}</span>
        </div>

        <input label=" Turkish Sound File" :v-model="form.t_sound_file" type="file" @change="GetTurkishName"
          id="t_sound_file" name="t_sound_file" accept="audio/*" style="display: none" class="form-control"
          placeholder="Enter Turkish Word" />

        <div class="text-danger" style="font-size: 14px" v-if="form.errors.has('t_sound_file')"
          v-html="form.errors.get('t_sound_file')" />
        <br />

        <label class="lablestyle">English Sound File</label>
        <br />
        <div class="ButtonForFile">
          <input type="button" id="loadFileXml" value="Choose File"
            onclick="document.getElementById('e_sound_file').click();" /><span class="typeButtonText">
            {{ word.e_sound_file ? word.e_sound_file : "No file chosen" }}</span>
        </div>

        <input style="display: none" class="form-control" @change="GetEnglishName" label=" English Sound File"
          :v-model="form.e_sound_file" type="file" id="e_sound_file" name="e_sound_file" accept="audio/*" />

        <div class="text-danger" style="font-size: 14px" v-if="form.errors.has('e_sound_file')"
          v-html="form.errors.get('e_sound_file')" />
        <br />
        <label class="lablestyle">
          Image<span class="text-danger"> *</span></label>

        <base-input style="padding-bottom: 8px" v-model="form.image" @change="onFileChange" type="file" name="image"
          id="image" placeholder="" accept="image/*" />
        <img class="imgg" :src="base_url + img_folder + word.image" style="margin-top: -20px" alt="" />
        <img :src="imagee" style="width: 50px" alt="" />

        <span class="text-danger" style="font-size: 13px" v-if="errors.image">{{
        errors.image[0]
        }}</span>

        <div class="text-right">
          <button type="submit mt-1 mb-5" style="background: #1f7dad" class="mb-5 btn btn-primary"
            :disabled="form.busy">
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import BackButton from "../ButtonComponent/BackButton.vue";

export default {
  components: {
    Loader1,
    ToasterU,
    BackButton,
  },
  data() {
    return {
      word: {},
      loader: false,
      errors: [],
      characters: [],
      toaster: false,
      max: 100,
      max1: 100,
      a: "ee",
      charName: null,
      img_folder: "/images/",
      base_url: this.$appName,
      imagee: "",
      form: new Form({
        id: "",
        image: "",
        tname: "",
        t_sound_file: "",
        e_sound_file: "",
        ename: "",
        // character: "",
      }),
    };
  },

  created() {
    // console.log(this.$appName + `/api/edit/${this.$route.params.id}`);
    axios
      .get(this.$appName + `/api/word/edit/${this.$route.params.id}`)
      .then((res) => {
        console.log(res.data.character.name);
        this.charName = res.data.character.name;
        this.word = res.data;
        // this.splitTurkName = res.data.t_sound_file.split(/[ ,]+/);
        // this.splitEngName = res.data.e_sound_file.split(/[ ,]+/);
        // this.word.t_sound_file = this.splitTurkName[1];
        // this.word.e_sound_file = this.splitEngName[1];
      });
  },

  mounted() {
    axios.get(this.$appName + "/api/word/characters").then((res) => {
      //pushing data to items that will show inside table
      this.characters = res.data;
    });
  },

  methods: {
    selectId(e) {
      this.selectedId = e.id;
    },

    GetTurkishName(event) {
      this.word.t_sound_file = event.target.files[0].name;
    },
    GetEnglishName(event) {
      this.word.e_sound_file = event.target.files[0].name;
    },
    updateWord() {
      this.loader = true;
      // alert(this.selectedId );
      let data = new FormData();
      data.append("id", this.word.id);
      data.append("tname", this.word.tname);
      data.append("ename", this.word.ename);
      data.append("character", this.selectedId);
      data.append("image", document.getElementById("image").files[0]);
      data.append(
        "t_sound_file",
        document.getElementById("t_sound_file").files[0]
      );
      data.append(
        "e_sound_file",
        document.getElementById("e_sound_file").files[0]
      );

      axios
        .post(this.$appName + `/api/word/Word`, data)
        .then((response) => {
          console.log("response", response);
          this.loader = false;
          this.toaster = true;
          setTimeout(() => {
            this.$router.push({ path: "/word" });
          }, 0);
        })
        .catch((error) => {
          this.loader = false;

          this.errors = error.response.data.errors;
        });
    },

    onFileChange(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      // this.loader = true;
      reader.onload = (e) => {
        this.base_url = "";
        this.img_folder = "";
        this.word.image = e.target.result;
        // this.loader = false;
      };
    },
  },
};
</script>

<style>
.lablestyle {
  color: #525f7f;
  font-size: 0.935rem;
  font-weight: 600;
}

.form-control {
  padding: 5px 10px !important;
  height: calc(1.5em + 1.01rem + 2px) !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.vs__dropdown-menu {
  height: 150px !important;
  font-size: 0.875rem !important;
}

.vs__selected {
  color: #495057;
}

.vs__search {
  padding: 5px 10px !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
}

.vs__search:focus {
  color: #495057;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.vs--single .vs__selected {
  /* color: #8898aa; */
  font-size: 0.875rem !important;
}

a.h4.mb-0.text-white.text-uppercase.d-none.d-lg-inline-block.active.router-link-active {
  display: none !important;
}
</style>
