<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>

    <div class="container-fluid mt-6" style="background-color: white">
      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <router-link to="/word/add">
            <AddButton />
          </router-link>
        </div>
        <BulkAction @bulkActions="bulkActions" />
      </div>
      <ImageSlideshow
        v-if="sildeShowFlag"
        @sildeImageClose="sildeImageClose"
        :getImages="getImages"
        :index="index"
      />

      <div class="row">
        <div class="col-12 text-right p-0">
          <button
            v-b-modal.modal-1
            class="btn btn-dark my-2 mr-2 btnst import_utlity"
          >
            <font-awesome-icon
              style="font-size: 16px; padding: 0 1px"
              icon="fa-solid fa-plus"
            />
            Import Utility
          </button>
          <form
            ref="form"
            @submit.prevent="submitUtlity"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <b-modal
              id="modal-1"
              title="Import"
              @cancel="methodClose"
              @close="methodClose"
              @ok="handleOk"
              no-close-on-backdrop
            >
              <base-input
                type="file"
                id="fileimport"
                name="fileimport"
                v-model="form.fileimport"
                style="margin-bottom: 0rem"
              />

              <span
                class="text-danger"
                style="font-size: 13px; margin-top: -10px"
                v-if="errors.file"
                >{{ errors.file[0] }}</span
              >

              <span
                class="text-danger"
                style="font-size: 13px; margin-top: -10px"
                v-if="this.alert"
                >{{ this.alertText }} {{ this.alertRowNum }}
                {{ this.alertDuplicate }}
                <br />
              </span>

              <span
                class="text-success"
                style="font-size: 13px; margin-top: -10px"
                v-if="this.alert"
                >{{ this.alertSuccess }}
              </span>
            </b-modal>
          </form>
        </div>

        <div class="col-12 mb-5">
          <h2 style="font-size: 1.5rem; margin-bottom: -11px">
            Words ({{ wordsLenght }})
          </h2>
        </div>
        <div class="col-12" style="margin-top: -12px">
          <div class="alert alert-danger" v-if="errorC">
            <span>{{ errorS }}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />

            <b-table
              :items="words"
              :busy.sync="isBusy"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>
              <template v-slot:cell(id)="words">
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="words.item.id"
                  name="id[]"
                  number
                />
              </template>

              <template v-slot:cell(image)="words">
                <img
                  @click="indexImage(words.item.id)"
                  v-if="words.item.image != null"
                  style="width: 50px !important"
                  class="imgg"
                  :src="imgUrl + '/images/' + words.item.image"
                />
                <img
                  v-else
                  @click="indexImage('static')"
                  style="width: 50px !important"
                  class="imgg"
                  :src="imgUrl + '/images/defaultYMT.png'"
                />
              </template>

              <template v-slot:cell(character_id)="words">
                <!-- {{words.item.character_id}} -->
                <router-link
                  :to="{
                    name: 'View Words',
                    params: { characterID: words.item.character_id },
                  }"
                >
                  <span style="text-decoration: underline">{{
                    words.item.character.name
                  }}</span>
                </router-link>
              </template>

              <template v-slot:cell(e_sound_file)="words">
                <EnglishSoundTag
                  :imgUrl="imgUrl"
                  :soundUrl="words.item.e_sound_file"
                />
              </template>

              <template v-slot:cell(t_sound_file)="words">
                <TurkishSoundTag
                  :imgUrl="imgUrl"
                  :soundUrl="words.item.t_sound_file"
                />
              </template>
              <template v-slot:cell(status)="words">
                <b-form-checkbox
                  @click.native="singleAct(words.item.id)"
                  v-if="words.item.status == 'activate'"
                  name="check-button"
                  switch
                  checked="true"
                >
                </b-form-checkbox>

                <b-form-checkbox
                  @click.native="singleDct(words.item.id)"
                  v-else
                  name="check-button"
                  switch
                  checked="false"
                >
                </b-form-checkbox>
              </template>

              <template v-slot:cell(action)="words">
                <router-link
                  :to="{ name: 'Edit utility', params: { id: words.item.id } }"
                >
                  <font-awesome-icon
                    class="fontIcon"
                    icon="fa-solid fa-pencil"
                  />
                </router-link>
                <a
                  @click="deleteWord(words.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    class="fontIcon"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>
<script src="//cdn.jsdelivr.net/npm/vue-aplayer"></script>
<!-- <script src="APlayer.min.js"></script> -->

<script>
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import Loader1 from "../Loader1";

import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import AddButton from "../ButtonComponent/AddButton.vue";
import BulkAction from "../ButtonComponent/BulkAction.vue";
import ImageSlideshow from "../Components/ImageSlideshow.vue";
import TurkishSoundTag from "../Components/TurkishSoundTag.vue";
import EnglishSoundTag from "../Components/EnglishSoundTag.vue";
import VueGallerySlideshow from "vue-gallery-slideshow";
import Aplayer from "vue-aplayer";

export default {
  components: {
    Loader1,
    ToasterDe,
    ToasterAc,
    ToasterD,
    AddButton,
    BulkAction,
    VueGallerySlideshow,
    Aplayer,
    TurkishSoundTag,
    ImageSlideshow,
    EnglishSoundTag,
  },
  data() {
    return {
      words: [],
      char_names: [],
      errors: [],
      record: {},
      toasterac: false,
      // flag:false,
      sildeShowFlag: false,
      getImages: [],
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      isBusy: false,
      checkedNames: [],
      toasterde: false,
      toasterd: false,
      isActive: false,
      errorC: false,
      errorS: "",
      // onFiltered: "",
      index: null,

      alertDuplicate: "",
      alert: false,
      perPage: 10,
      rows: "",
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      cheKID: [],
      isDelete: true,
      isUpdate: true,
      // items: "",
      wordsLenght: null,
      selected: [],
      allSelected: false,
      count: 0,
      status: "",
      loader: false,
      isPlay: false,
      isSmall: false,
      imgUrl: this.$appName,
      itemm: "ew",
      form: new Form({
        status: "",
        id: "",
        fileimport: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "image", label: "Image", tdClass: "align-middle" },

        { key: "character_id", label: "Character", tdClass: "align-middle" },
        { key: "tname", label: "Turkish Word", tdClass: "align-middle" },

        { key: "ename", label: "English Word", tdClass: "align-middle" },
        {
          key: "e_sound_file",
          label: "English Sound File",
          tdClass: "align-middle",
        },
        {
          key: "t_sound_file",
          label: "Turkish Sound File",
          tdClass: "align-middle",
        },
        { key: "status", label: "Status", tdClass: "align-middle" },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {
    this.getWord();
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.wordsLenght = filteredItems.length;
      this.currentPage = 1;
    },
    bulkActions(type) {
      if (type == "delete") {
        this.status = "delete";
      } else if (type == "activate") {
        this.status = "activate";
      } else {
        this.status = "deactivate";
      }
      this.changeStatus();
    },

    deleteWord(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loader = true;
          //  location.reload();

          axios
            .delete(this.$appName + `/api/word/Word/${id}`)
            .then((response) => {
              this.loader = false;
              this.toasterd = false;
              if (response.data == "Assigned") {
                this.errorC = true;
                this.errorS =
                  "You can't delete this word, because it is assigned in category.";
              } else if (response.data == "AssignedWord") {
                this.errorC = true;
                this.errorS =
                  "You can't delete this word, because it is assigned in opposite words.";
              } else if (response.data == "AssignedSingular") {
                this.errorC = true;
                this.errorS =
                  "You can't delete this word, because it is assigned in singular plurals.";
              } else {
                this.errorC = false;
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
                let i = this.words.map((data) => data.id).indexOf(id);
                this.words.splice(i, 1);
                this.isDelete = false;
                this.wordsLenght = this.words.length;
                this.rows = this.words.length;
                this.toasterd = true;
              }

              this.allSelected = false;
              setTimeout(() => {
                this.errorC = false;
              }, 3000);

              this.getWord();
              // location.reload();
            });
        }
      });
    },

    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;

        this.count = this.words.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },

    getWord() {
      // this.loader = true;
      axios.get(this.$appName + "/api/word/show").then((res) => {
        //pushing data to items that will show inside table
        this.words = res.data;
        this.getImages = this.words.map((item) => {
          return {
            id: item.id,
            name: this.imgUrl + "/images/" + item.image,
          };
        });

        if (this.getImages.length > 0) {
          this.getImages = Object.assign(
            ...this.getImages.map(({ id, name }) => ({ [id]: name }))
          );
        }

        this.wordsLenght = res.data.length;
        this.rows = res.data.length;
      });
    },

    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);
        if (this.status != "delete") {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;

              axios
                .post(this.$appName + "/api/word/statusChange", data)
                .then((response) => {
                  this.isDelete = false;
                  this.loader = false;
                  if (this.status == "deactivate") {
                    this.toasterde = true;
                  } else {
                    this.toasterac = true;
                  }
                  this.isUpdate = false;
                  this.allSelected = false;
                  this.selected = [];
                  this.getWord();
                  // location.reload();
                });
            }
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;
              axios
                .post(this.$appName + "/api/word/statusChange", data)
                .then((response) => {
                  if (response.data == "Assigned") {
                    this.errorC = true;
                    this.errorS =
                      "You can't delete this word, because it is assigned in category.";
                  } else if (response.data == "AssignedWord") {
                    this.errorC = true;
                    this.errorS =
                      "You can't delete this word, because it is assigned in opposite words.";
                  } else if (response.data == "AssignedSingular") {
                    this.errorC = true;
                    this.errorS =
                      "You can't delete this word, because it is assigned in singular plurals.";
                  } else {
                    this.toasterd = true;
                  }
                  this.isDelete = false;
                  this.loader = false;
                  this.getWord();
                  // location.reload();
                  setTimeout(() => {
                    this.isUpdate = false;
                    this.allSelected = false;
                    this.selected = [];
                    this.errorC = false;
                  }, 3000);
                });
            }
          });
        }
      } else {
        alert("Please select atleast one record");
      }
    },

    singleAct(id) {
      this.loader = true;
      axios
        .post(this.$appName + `/api/word/changeDeactivate/${id}`)
        .then((response) => {
          this.loader = false;
          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    singleDct(id) {
      this.loader = true;
      axios
        .post(this.$appName + `/api/word/changeActivate/${id}`)
        .then((response) => {
          this.loader = false;
          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitUtlity();
    },

    methodClose() {
      location.reload();
    },

    indexImage(key) {
      this.index = key;
      this.sildeShowFlag = true;
    },

    sildeImageClose(value) {
      this.sildeShowFlag = value;
      this.index = null;
    },
    submitUtlity() {
      this.loader = true;
      let data = new FormData();
      data.append("file", document.getElementById("fileimport").files[0]);

      axios
        .post(this.$appName + "/api/word/import", data)
        .then((response) => {
          this.loader = false;
          if (!response.data.message) {
            location.reload();
          }
          // location.reload();
          this.alert = true;

          //for get erorrs from upload file
          this.alertText = response.data.message;
          this.alertRowNum = response.data.rownum;
          //for get duplicate record errors from upload file
          this.alertText = response.data.message;
          this.alertDuplicate = response.data.dup;
          this.alertSuccess = response.data.sucess;
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

<style></style>
