<template>

    <button
      type="button"
      class="btn btn-primary btn-sm"
      style="
        background: #1f7dad;
        border-color: #1f7dad;
        border-radius: 26px;
        font-size: 13px;
        padding: 6px 11px 6px 11px;
      "
      @click="$router.go(-1)"
    >
      Back
    </button>
</template>

<script>
export default {
  name: "BackButton",
};
</script>
