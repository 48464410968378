<template>
  <div class="col-md-8 text-right mt-2 mb-2 p-0">
    <button
      class="btn btn-danger my-2 mr-2 btnst"
      @click="bulkActions('delete')"
      id="delete"
      style=""
    >
      Delete
    </button>
  </div>
</template>

<script>
export default {
  name: "DeleteButton",

  methods: {
    bulkActions(type) {
      this.$emit("bulkActions", type);
    },
    
  },
};
</script>
