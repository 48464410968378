<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <div>
        <button
          type="button"
          class="btn btn-primary btn-sm btnst"
          style="background: #1f7dad; border-color: #1f7dad"
          @click="$router.go(-1)"
        >
          Back
        </button>
        <!-- <i class="ni ni-bold-left"></i>  <button>Back</button> -->
      </div>

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Question</h2>
        <h2 v-else style="font-size: 1.5rem">Add Question</h2>
      </div>
      <form
        method="post"
        @submit.prevent="AddQuestion"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <label class="lablestyle">
          Name<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.name"
          class="form-control"
          type="text"
          name="name"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
          :maxlength="max"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.name"
              >{{ errors.name[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max - form.name.length"> </span>
            </div>
          </div>
        </div>

        <div>
          <label class="lablestyle">
            Answer<span class="text-danger"> *</span></label
          >

          <v-select
            v-model="selectOption"
            :options="options"
            placeholder="Select Answer"
            label="options"
            @input="selectId($event)"
          ></v-select>

          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.answer"
            >{{ errors.answer[0] }}</span
          >
          <br />

          <label class="lablestyle"> Turkish Sound File</label>

          <input
            :v-model="form.t_sound_file"
            type="file"
            id="t_sound_file"
            name="t_sound_file"
            style="margin-bottom: 1px"
            placeholder="Enter Turkish Word"
            accept="audio/mp3,audio/wav"
            class="form-control"
          />
          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.t_sound_file"
            >{{ errors.t_sound_file[0] }}</span
          >
          <br />

          <label class="lablestyle">
            Image<span class="text-danger"> *</span></label
          >
          <input
            class="form-control"
            :v-model="form.image"
            type="file"
            id="image"
            name="image"
            placeholder="Enter Name"
            style="margin-bottom: 1px"
            accept="image/*"
          />

          <img
            v-if="form.id"
            class="imgg mt-2"
            :src="base_url + img_folder + form.image"
            style=""
            alt=""
          />
          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.image"
            >{{ errors.image[0] }}</span
          >
          <br />
        </div>

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
  },

  data() {
    return {
      errors: [],
      loader: false,
      toaster: false,
      options: [],
      alert: false,
      level_id: null,
      selectOption: "",
      img_folder: "/images/",
      base_url: this.$appName,
      max: 50,

      activities: null,
      form: new Form({
        id: "",
        name: "",
        answer: "",
        image: "",
        activity_id: "",
        t_sound_file: "",
      }),
    };
  },

  created() {
    // console.log(this.$appName + `/api/edit/${this.$route.params.id}`);
    if (this.$route.params.id != null) {
      axios
        .get(this.$appName + `/api/question/show/${this.$route.params.id}`)
        .then((res) => {
          console.log(res.data);
          this.form = res.data;
          this.selectOption = res.data.answer;
          // console.log("anss--", res.data);
        });
    }
  },

  mounted() {
    this.activityID = localStorage.getItem("activityID");

    axios
      .get(this.$appName + `/api/question/getOption/${this.activityID}`)
      .then((res) => {
        this.options = res.data;
        //   this.form = res.data;
      });
  },

  methods: {
    selectId(e) {
      this.form.answer = e.options;
    },
    async AddQuestion() {
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("name", this.form.name);
      data.append("answer", this.form.answer);
      data.append("activity_id", this.activityID);
      data.append("image", document.getElementById("image").files[0]);
      data.append(
        "t_sound_file",
        document.getElementById("t_sound_file").files[0]
      );

      let addNewActivity = await axios
        .post(this.$appName + "/api/question/Question", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewActivity) {
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;
        setTimeout(() => {
          this.$router.push({
            name: "Add activity",
            params: { idd: this.$activity2 },
          });
        }, 0);
      }
    },
  },
};
</script>

<style scoped>
.vs__dropdown-menu {
  height: auto !important;

  font-size: 0.875rem !important;
}
</style>
