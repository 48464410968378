<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />

      <BackButton />

      <!-- <div>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          style="
            background: #1f7dad;
            border-color: #1f7dad;
            border-radius: 26px;
            font-size: 13px;
            padding: 6px 11px 6px 11px;
          "
          @click="$router.go(-1)"
        >
          Back
        </button>
      </div> -->

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Number</h2>
        <h2 v-else style="font-size: 1.5rem">Add Number</h2>
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="submitLevel"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <label class="lablestyle">
          Enter Number<span class="text-danger"> *</span></label
        >
        <input type="hidden" v-model="form.id" />

        <input
          type="number"
          id="name"
          @input="getNumbers"
          name="name"
          placeholder="Enter Number"
          v-model="form.number"
          style="margin-bottom: 0rem"
          class="form-control"
        />
        <span
          class="text-danger"
          style="font-size: 13px; margin-top: -10px"
          v-if="errors.number"
          >{{ errors.number[0] }}</span
        >

        <br />
        <label class="lablestyle">
          Turkish Name<span class="text-danger"> *</span></label
        >
        <input
          v-model="form.name"
          class="form-control"
          type="text"
          name="name"
          placeholder="Enter Name"
          :maxlength="max4"
          style="margin-bottom: 1px"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.name"
              >{{ errors.name[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max4 - form.name.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle"> Turkish sound file </label>

        <input
          :v-model="form.t_sound_file"
          class="form-control"
          type="file"
          name="name"
          id="t_sound_file"
          style="margin-bottom: 1px"
          accept="audio/*"
        />

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.t_sound_file"
          >{{ errors.t_sound_file[0] }}</span
        >
        <br />

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterA from "../../ToasterA";
import ToasterU from "../../ToasterU";
import BackButton from "../../ButtonComponent/BackButton.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    BackButton,
  },

  data() {
    return {
      errors: [],
      loader: false,
      toaster: false,
      toasterprop: false,
      max4: 50,
      alert: false,
      form: new Form({
        id: "",
        t_sound_file: "",
        name: "",
      }),
    };
  },

  created() {
    // console.log(this.$appName + `/api/edit/${this.$route.params.id}`);
    if (this.$route.params.id != undefined) {
      axios
        .get(this.$appName + `/api/number/Number/${this.$route.params.id}`)
        .then((res) => {
          console.log(res.data.name);
          this.form = res.data;
        });
    }
  },

  methods: {
    getNumbers(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },

    submitLevel() {
      this.loader = true;
      let data = new FormData();
      data.append("number", this.form.number);
      data.append("name", this.form.name);
      data.append(
        "t_sound_file",
        document.getElementById("t_sound_file").files[0]
      );
      data.append("id", this.form.id);
      axios
        .post(this.$appName + "/api/number/Number", data)
        .then((response) => {
          this.loader = false;
          this.toaster = true;
          this.toasterprop = true;
          this.loader = false;
          setTimeout(() => {
            this.$router.push({
              name: "Add question5",
            });
          }, 0);

          console.log(response.data);
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
          console.log(this.errors);
        });
    },
  },
};
</script>

<style></style>
