<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <ImageSlideshow
        v-if="sildeShowFlag"
        @sildeImageClose="sildeImageClose"
        :getImages="getImages"
        :index="index"
      />

      <div>
        <router-link to="/level3/activity/view3/3.4">
          <button
            type="button"
            class="btn btn-primary btn-sm btnst"
            style="background: #1f7dad; border-color: #1f7dad"
          >
            Back
          </button>
        </router-link>
      </div>
      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Comprehension</h2>
        <h2 v-else style="font-size: 1.5rem">Add Comprehension</h2>
      </div>

      <div class="alert alert-danger" v-if="errorR">
        <span
          class="text-danger"
          style="font-size: 13px; color: white !important"
          v-if="errorR"
        >
          {{ this.errorC }}</span
        >
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="AddActivity"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <input
          v-model="sublevel_name"
          class="form-control"
          type="hidden"
          readonly
          value="2.1.1"
          name="name"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <input
          v-model="form.name"
          class="form-control"
          type="hidden"
          readonly
          name="name"
          placeholder="Enter Title"
          style="margin-bottom: 1px"
        />

        <label class="lablestyle">
          Title<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.title"
          class="form-control"
          type="text"
          name="name"
          placeholder="Enter Title"
          style="margin-bottom: 1px"
          :maxlength="max"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.title"
              >{{ errors.title[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max - form.title.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle"> Turkish sound file </label>

        <input
          v-if="form.id"
          :v-model="form.t_sound_file"
          type="file"
          @change="GetTurkishName"
          style="display: none"
          id="t_sound_file"
          name="t_sound_file"
          accept="audio/*"
          class="form-control"
          placeholder="Enter Turkish Word"
        />
        <input
          v-else
          :v-model="form.t_sound_file"
          type="file"
          id="t_sound_file"
          name="t_sound_file"
          accept="audio/*"
          class="form-control"
          placeholder="Enter Turkish Word"
        />
        <div class="ButtonForFile" v-if="form.id">
          <input
            class=""
            type="button"
            id="loadFileXml"
            value="Choose File"
            onclick="document.getElementById('t_sound_file').click();"
          /><span class="typeButtonText">
            &nbsp;{{
              form.t_sound_file ? form.t_sound_file : "No file chosen"
            }}</span
          >
        </div>

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.t_sound_file"
          >{{ errors.t_sound_file[0] }}</span
        >
        <br />

        <label class="lablestyle">
          Number of sentence<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.numberofSentence"
          class="form-control"
          type="number"
          @input="getNumbers"
          name="name"
          placeholder="Enter number of sentence "
          style="margin-bottom: 1px"
        />

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.numberofSentence"
          >{{ errors.numberofSentence[0] }}</span
        >
        <div
          class="row mt-3"
          v-for="(arrNum, index) in arrayNumber"
          :key="index"
        >
          <div class="col-6">
            <label class="lablestyle">
              Turkish sentence {{ arrNum
              }}<span class="text-danger"> *</span></label
            >

            <input
              v-model="form.t_sentence[index]"
              class="form-control"
              type="text"
              placeholder="Enter turkish sentence"
              style="margin-bottom: 1px"
            />
          </div>
          <div class="col-6">
            <label class="lablestyle">
              English sentence {{ arrNum
              }}<span class="text-danger"> *</span></label
            >
            <input
              v-model="form.e_sentence[index]"
              class="form-control"
              type="text"
              placeholder="Enter english sentence"
              style="margin-bottom: 1px"
            />
          </div>
        </div>

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-4 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>

      <div class="" v-if="form.id">
        <hr />
        <div class="row">
          <div class="col-12 text-right p-0"></div>
        </div>
        <div class="row">
          <div class="col-md-4 mb-2 mt-2 pl-2.5">
            <router-link
              :to="{
                name: 'Question add3.4',
                params: { acivitityID: this.$route.params.id },
              }"
            >
              <base-button
                type="button"
                class="btn btn-primary btn-sm my-2 mr-2 btnst"
                style="
                  background: #1f7dad;
                  border-color: #1f7dad;
                  padding: 6px 11px 6px 11px;
                "
                >Add</base-button
              >
            </router-link>
          </div>

          <div class="col-md-8 text-right mt-2 mb-2 pr-2">
            <button
              class="btn btn-danger statusBtn my-2 mr-2 btnst"
              style="border-radius: 26px"
              @click="deletee"
              id="delete"
            >
              Delete
            </button>
          </div>
        </div>

        <h2 style="font-size: 1.5rem">Questions ({{ levelLenght }})</h2>
        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 col-md-6 p-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-6 float-right p-0"
              style="margin-top: 3px"
            >
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right float-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="table-responsive">
              <form
                method="post"
                @submit.prevent="changeStatus"
                @keydown="form.onKeydown($event)"
                enctype="multipart/form-data"
              >
                <input type="hidden" v-model="status" name="status" id="" />
                <b-table
                  :items="category"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  ref="selectableTable"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  empty-text="No record found"
                  empty-filtered-text="No search result found"
                  @filtered="onFiltered"
                >
                  <template #head(id)>
                    <input
                      type="checkbox"
                      v-model="allSelected"
                      @change="selectAll"
                    />
                  </template>

                  <template v-slot:cell(id)="category">
                    <input
                      type="checkbox"
                      v-model="selected"
                      :value="category.item.id"
                      name="id[]"
                      number
                    />
                  </template>

                  <template v-slot:cell(image)="category">
                    <img
                      @click="indexImage(category.item.id)"
                      v-if="category.item.image != null"
                      style="width: 50px !important"
                      class="imgg"
                      :src="imgUrl + '/images/' + category.item.image"
                    />
                    <img
                      v-else
                      @click="indexImage('static')"
                      style="width: 50px !important"
                      class="imgg"
                      :src="imgUrl + '/images/defaultYMT.png'"
                    />
                  </template>

                  <template v-slot:cell(t_sound_file)="category">
                    <TurkishSoundTag
                      :imgUrl="imgUrl"
                      :soundUrl="category.item.t_sound_file"
                    />
                  </template>
                  <template v-slot:cell(action)="category">
                    <router-link
                      :to="{
                        name: 'Edit questionn3.4',
                        params: {
                          id: category.item.id,
                          acivitityID: $route.params.id,
                        },
                      }"
                    >
                      <font-awesome-icon
                        style="font-size: 16px; padding: 0 4px"
                        icon="fa-solid fa-pencil"
                      />
                    </router-link>
                    <a
                      @click="deleteQuestion(category.item.id)"
                      style="font-size: 16px; color: #dc3545"
                    >
                      <font-awesome-icon
                        style="font-size: 16px; padding: 0 4px"
                        icon="fa-solid fa-trash-can"
                      />
                    </a>
                  </template>
                </b-table>
              </form>

              <br />
              <div class="col-sm-12 col-md-6 float-right p-0" style="">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right mb-3"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterA from "../../ToasterA";
import ToasterU from "../../ToasterU";
import Swal from "sweetalert2";
import ToasterAc from "../../ToasterAc";
import ToasterDe from "../../ToasterDe";
import ToasterD from "../../ToasterD";
import ImageSlideshow from "../../Components/ImageSlideshow.vue";
import TurkishSoundTag from "../../Components/TurkishSoundTag.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    ToasterDe,
    ToasterAc,
    ToasterD,
    TurkishSoundTag,
    ImageSlideshow,
  },

  data() {
    return {
      inputs: [
        {
          options: "",
        },
      ],

      errors: [],
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      t_sentence: [],
      e_sentence: [],
      sildeShowFlag: false,
      alertDuplicate: "",
      toasterac: false,
      toasterde: false,
      toasterd: false,
      rows: "",
      errorR: false,
      max: 50,
      arrNum: [],
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,
      max1: 50,
      imgUrl: this.$appName,
      countArray: [],
      toaster: false,
      toasterprop: false,
      answer: "",
      errorC: "",

      arrayNumber: [],
      sublevel_id: "12",
      sublevel_name: "",
      img_folder: "/images/",
      base_url: this.$appName,
      category: [],
      totalNum: "",
      t_sound_file: "",
      subLevelName: "",
      form: new Form({
        id: "",
        name: "3.4",
        t_sound_file: "",
        t_sentence: [],
        e_sentence: [],
        numberofSentence: "",
        title: "",
        countComprehension: "",
        option: "",
        sublevel_id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        {
          key: "image",
          label: "Image",
          tdClass: "align-middle",
        },
        {
          key: "e_sentence",
          label: "English Question",
          tdClass: "align-middle",
        },
        {
          key: "question",
          label: "Turkish Question",
          tdClass: "align-middle",
        },

        { key: "answer", label: "Answer", tdClass: "align-middle" },
        {
          key: "t_sound_file",
          label: "Turkish Sound File",
          tdClass: "align-middle",
        },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  created() {
    axios
      .get(this.$appName + `/api/activity/edit2/${this.$route.params.id}`)
      .then((res) => {
        if (res.data.id != undefined) {
          this.form = res.data;
          this.form.numberofSentence = res.data.comprehension.length;
          this.arrayNumber = res.data.comprehension.length;
          this.countArray = res.data.comprehension;

          this.form.e_sentence = res.data.comprehension.map(
            (data) => data.e_sentence
          );
          this.form.t_sentence = res.data.comprehension.map(
            (data) => data.t_sentence
          );
        }
      });
  },

  mounted() {
    this.getActivity();
  },

  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },

    getActivity() {
      axios
        .get(this.$appName + `/api/question/Question/${this.$route.params.id}`)
        .then((res) => {
          this.category = res.data;
          this.getImages = this.category.map((item) => {
            return {
              id: item.id,
              name: this.imgUrl + "/images/" + item.image,
            };
          });

          if (this.getImages.length > 0) {
            this.getImages = Object.assign(
              ...this.getImages.map(({ id, name }) => ({ [id]: name }))
            );
          }

          this.levelLenght = res.data.length;
          this.rows = res.data.length;
        });
    },
    GetTurkishName(event) {
      this.form.t_sound_file = event.target.files[0].name;
    },
    getNumbers(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      if (e.target.value <= 50) {
        this.totalNum = this.form.numberofSentence;
      } else {
        this.form.numberofSentence = "";
        this.totalNum = this.form.numberofSentence;
      }
      this.arrayNumber = [];
      for (let i = 1; i <= this.totalNum; i++) {
        this.arrayNumber.push(i);
      }
    },

    // getNumbers() {
    //   this.arrayNumber = [];
    //   this.totalNum = this.form.numberofSentence;
    //   for (let i = 1; i <= this.totalNum; i++) {
    //     this.arrayNumber.push(i);
    //   }

    // },

    // saveActivityID() {
    //   localStorage.setItem("activityID", this.$route.params.id);
    //   this.$router.push({
    //     name: "Question add3.4",
    //   });
    // },

    async AddActivity() {
      this.loader = true;

      if (this.arrayNumber.length == undefined) {
        this.countComprehension = this.countArray.length;
      } else {
        this.countComprehension = this.arrayNumber.length;
      }

      let data = new FormData();
      data.append("id", this.form.id);
      data.append("name", this.form.name);
      data.append("title", this.form.title);
      data.append("countComprehension", this.countComprehension);
      data.append("t_sentence", JSON.stringify(this.form.t_sentence));
      data.append("e_sentence", JSON.stringify(this.form.e_sentence));
      data.append("sublevel_id", this.sublevel_id);
      data.append(
        "t_sound_file",
        document.getElementById("t_sound_file").files[0]
      );

      if (this.form.numberofSentence > 50) {
        this.errorR = true;
        this.loader = false;
        this.errorC =
          "The number of sentence may not be greater than 50 numbers.";
      } else {
        let addNewActivity = await axios
          .post(this.$appName + "/api/activity/store1", data)
          .catch((error) => {
            this.loader = false;
            this.errors = error.response.data.errors;
          });
        if (addNewActivity) {
          this.loader = false;
          if (addNewActivity.data == "required") {
            // this.loader = false;
            this.toaster = false;
            this.errorR = true;
            this.errorC = "All fields are required.";
          } else {
            this.errorR = false;
            this.toaster = true;
            this.toasterprop = true;
            // this.loader = false;
            setTimeout(() => {
              this.$router.push({
                name: "View activity3.4",
                params: { idd: this.$activity7 },
              });
            }, 0);
          }
        }
      }
    },

    async deletee() {
      this.changeStatus();
    },
    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);

        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;

            axios
              .post(this.$appName + "/api/question/deleteQue", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;
                this.toasterd = true;

                // location.reload();
                this.getActivity();
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },
    indexImage(key) {
      this.index = key;
      this.sildeShowFlag = true;
    },
    sildeImageClose(value) {
      this.sildeShowFlag = value;
      this.index = null;
    },

    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;
        this.count = this.category.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },
    deleteQuestion(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          axios
            .delete(this.$appName + `/api/question/Question/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.category.map((data) => data.id).indexOf(id);
              this.category.splice(i, 1);
              this.loader = false;

              this.isDelete = false;
            });
        }
      });
    },
  },
};
</script>

<style></style>
