<template>
  <div class="vertical-center">
    <Loader1 v-if="loader" />
    <div class="container align">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7 mb-5" style="">
          <div
            class="card bg-secondary border-0"
            style="background-color: white !important"
          >
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <img width="180" src="/img/YMT_logo_updated.svg" />
              </div>
              <!-- <form role="form" @submit.prevent="handleSubmit()"> -->
              <base-input
                style="margin-bottom: 1px !important"
                type="email"
                class="mb-3 form_input"
                placeholder="Email"
                addon-left-icon="ni ni-email-83"
                v-model="form.email"
                name="Email"
              >
              </base-input>

              <span
                class="text-danger"
                style="font-size: 12px"
                v-if="errors.email"
                >{{ errors.email[0] }}</span
              >

              <span
                class="text-danger"
                style="font-size: 12px"
                v-if="customError"
                >{{ "The provided credentials are incorect." }}</span
              >
              <br />

              <base-input
                style="margin-bottom: 1px !important"
                alternative
                class="input-group form_input"
                placeholder="Password"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="form.password"
                name="Password"
              >
              </base-input>

              <span
                class="text-danger"
                style="font-size: 13px"
                v-if="errors.password"
                >{{ errors.password[0] }}</span
              >
              <br />

              <div class="text-center">
                <base-button
                  type="primary"
                  native-type="submit"
                  @click.prevent="login"
                  class="my-1"
                  style="border-radius: 26px"
                  >Sign in</base-button
                >
              </div>
              <!-- </form> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
// import formMixin from "@/mixins/form-mixin";
import Loader1 from "./Loader1";

import axios from "axios";
export default {
  name: "login",
  components: {
    Loader1,
  },

  // mixins: [formMixin],

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errors: [],
      customError: false,
      loader: false,
    };
  },
  methods: {
    login() {
      this.loader = true;
      axios
        .post(this.$appName + "/api/login", this.form)
        .then((response) => {
          // localStorage.setItem("token", response.data);
          this.loader = false;
          if (response.data == "invalidd") {
            this.customError = true;
          } else {
            localStorage.setItem("users", response.data.email);
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

<style scoped>
.input-group .form-control {
  padding: 5px 10px !important;
}
.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 5px !important;
}
.form-control {
  padding: 5px 10px !important;
}

#ofBar {
  display: none !important;
}
.vertical-center {
}
</style>
