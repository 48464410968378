<template>
  <base-button
    type="primary"
    style="font-size: 14px; background: #1f7dad; border-color: #1f7dad"
    class="my-2 ml-2 btnst"
    >Add</base-button
  >
</template>

<script>
export default {
  name: "AddButton",
};
</script>
