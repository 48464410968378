<template>
  <div id="app" class="container-fluid" style="">
    <base-header type="gradient-success" class="pb-6 pb-8 pt-md-8">
      <!-- Card stats -->
      <div class="row" style="">
        <div class="col-xl-4 col-lg-6">
          <stats-card title="Total Level" type="" sub-title="4" icon="ni ni-support-16"
            class="mb-4 mb-xl-0 cardsBackgroung">
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card title="Total Sub Level" type="" sub-title="16" icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0 cardsBackgroung">
            <!-- <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card title="Total Words" type="" :sub-title="wordsLenght" icon="ni ni-caps-small" class="mb-4 mb-xl-0">
          </stats-card>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-4 col-lg-6">
          <stats-card title="Total User" type="" :sub-title="totalUser" icon="ni ni-single-02" class="mb-4 mb-xl-0">
          </stats-card>
        </div>

        <div class="col-xl-4 col-lg-6">
          <stats-card title="Latest Session" type="" :sub-title="latest_session" icon="ni ni-mobile-button"
            class="mb-4 mb-xl-0">
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card title="Total Time" type="" :sub-title="totalTime" icon="ni ni-watch-time" class="mb-4 mb-xl-0">
          </stats-card>
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
// Charts
// import * as chartConfigs from "@/components/Charts/config";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import axios from "axios";
import globalFunction from "./TotalTime/TimeUsage";

export default {
  components: {},
  data() {
    return {
      wordsLenght: "",
      totalTime: "",
      totalUser: "",
      latest_session: "",
    };
  },

  mounted() {
    axios.get(this.$appName + "/api/user/usersTime").then((res) => {
      this.totalUser = res.data.total_user;
      this.wordsLenght = res.data.word.length;
      this.latest_session = res.data.latest_session;
      this.totalTime = globalFunction.secondsToDhms(res.data.total_usage);
    });
  },

  methods: {
  },
};
</script>
<style>

</style>
