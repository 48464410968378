<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <div>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          style="
            background: #1f7dad;
            border-color: #1f7dad;
            border-radius: 26px;
            font-size: 13px;
            padding: 6px 11px 6px 11px;
          "
          @click="$router.go(-1)"
        >
          Back
        </button>
        <!-- <i class="ni ni-bold-left"></i>  <button>Back</button> -->
      </div>

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Options</h2>
        <h2 v-else style="font-size: 1.5rem">Add Options</h2>
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="AddOption"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <label class="lablestyle">
          Option A<span class="text-danger"> *</span></label
        >
        <base-input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />
        <base-input
          v-model="this.sublevel_id"
          type="hidden"
          name="sublevel_id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <base-input

          v-model="form.optionA"
          type="text"
          name="name"
          placeholder="Enter Option A"
          style="margin-bottom: 1px"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.optionA"
          >{{ errors.optionA[0] }}</span
        >
        <br />

        <label class="lablestyle">
          Option B<span class="text-danger"> *</span></label
        >

        <base-input
          v-model="form.optionB"
          type="text"
          name="name"
          placeholder="Enter Option B"
          style="margin-bottom: 1px"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.optionB"
          >{{ errors.optionB[0] }}</span
        >
        <br />

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>

      <div v-if="!form.id">
        <div class="row">
          <div class="col-md-4 mb-2 mt-2 pl-2">
            <base-button
              @click="addActivity"
              type="primary"
              style="
                background: #1f7dad;
                border-color: #1f7dad;
            
                padding: 6px 11px 6px 11px;
              "
              class="my-2 ml-2 btnst"
              >Add</base-button>
          </div>

          <div class="col-md-8 text-right mt-2 mb-2 p-2" style="">
            <button
              class="btn btn-danger statusBtn my-2 mr-2 btnst"
              @click="deletee"
            >
              Delete
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h2 style="font-size: 1.5rem; margin-bottom: -11px">
              Activities ({{ levelLenght }})
            </h2>
          </div>
        </div>

        <br />
        <br />
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
                entries
              </label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right float-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="table-responsive">
            <form
              method="post"
              @submit.prevent="changeStatus"
              @keydown="form.onKeydown($event)"
              enctype="multipart/form-data"
            >
              <input type="hidden" v-model="status" name="status" id="" />

              <b-table
                :items="levels"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                ref="selectableTable"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                show-empty
                empty-text="No record found"
                empty-filtered-text="No search result found"
                @filtered="onFiltered"
              >
                <template #head(id)>
                  <input
                    type="checkbox"
                    v-model="allSelected"
                    @change="selectAll"
                  />
                </template>

                <template v-slot:cell(id)="levels">
                  <input
                    type="checkbox"
                    v-model="selected"
                    @change="statusCheck"
                    :value="levels.item.id"
                    name="id[]"
                    number
                  />
                </template>

                <template v-slot:cell(image)="levels">
                  <img
                    style="width: 50px !important"
                    class="imgg"
                    :src="imgUrl + '/images/' + levels.item.image"
                    alt="..."
                  />
                </template>
                <template v-slot:cell(answer)="levels">
                  {{ levels.item.answer }}
                </template>
                <template v-slot:cell(sub_level)="levels">
                  <router-link
                    :to="{
                      name: 'View sub level',
                      params: { id: levels.item.id },
                    }"
                  >
                    <span style="color: black">{{
                      levels.item.sub_level.length
                    }}</span>
                  </router-link>
                </template>
                <template v-slot:cell(status)="levels">
                  <b-form-checkbox
                    @click.native="singleAct(levels.item.id)"
                    v-if="levels.item.status == 'activate'"
                    name="check-button"
                    switch
                    checked="true"
                  >
                  </b-form-checkbox>

                  <b-form-checkbox
                    @click.native="singleDct(levels.item.id)"
                    v-else
                    name="check-button"
                    switch
                    checked="false"
                  >
                  </b-form-checkbox>
                </template>

                <template v-slot:cell(action)="levels">
                  <router-link
                    :to="{
                      name: 'Edit activity',
                      params: { id: levels.item.id },
                    }"
                  >
                    <font-awesome-icon
                      style="font-size: 16px; padding: 0 4px"
                      icon="fa-solid fa-pencil"
                    />
                  </router-link>
                  <a
                    @click="deleteLevel(levels.item.id)"
                    style="font-size: 16px; color: #dc3545"
                  >
                    <font-awesome-icon
                      style="font-size: 16px; padding: 0 4px"
                      icon="fa-solid fa-trash-can"
                    />
                  </a>
                </template>
              </b-table>
            </form>

            <br />
          </div>
        </div>
        <div
          class="col-sm-12 col-md-6 float-right p-0"
          style="margin-top: 13px"
        >
          <div
            class="dataTables_paginate paging_simple_numbers float-right mb-3"
          >
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
              ></b-pagination>
            </ul>
          </div>
        </div>
        
      </div>
      
    </div>
    <br />
        <br />

  
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import Swal from "sweetalert2";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
  },

  data() {
    return {
      levels: [],
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,
      imgUrl: this.$appName,

      toaster: false,
      toasterprop: false,
      sublevel_id: null,
      form: new Form({
        id: "",
        optionA: "",
        optionB: "",
        sublevel_id: "",
        status: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "image", label: "Image", tdClass: "align-middle" },
        { key: "name", label: "Name", tdClass: "align-middle" },
        { key: "answer", label: "Answer", tdClass: "align-middle" },
        // { key: "status", label: "Status", tdClass: "align-middle" },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  created() {
    if (this.$route.params.id != null) {
      axios
        .get(this.$appName + `/api/option/Option/${this.$route.params.id}`)
        .then((res) => {
          console.log(res.data);
          this.form = res.data;
          this.sublevel_id = res.data.sublevel_id;
          localStorage.removeItem("subLevelID");
        });
    }
  },

  mounted() {
    this.sublevel_id = localStorage.getItem("subLevelID");
    axios
      .get(this.$appName + `/api/activity/Activity/${this.sublevel_id}`)
      .then((res) => {
        this.levels = res.data;
        // console.log(  this.levels );
        this.levelLenght = this.levels.length;
        this.rows = this.levels.length;
      });
  },

  methods: {
    addActivity() {
      this.$router.push({ path: "/activity/add" });
    },

    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;

        this.count = this.category.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },

    async deletee() {
      // if (this.count > 0) {
      this.status = "delete";
      this.changeStatus();
      // } else {
      //   alert("Please select atleast one record");
      // }
    },

    changeStatus() {
      if (this.selected != "") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;
            let data = new FormData();
            data.append("status", this.status);
            data.append("id", this.selected);
            axios
              .post(this.$appName + "/api/activity/statusChange", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;
                location.reload();
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },
    deleteLevel(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");

          axios
            .delete(this.$appName + `/api/activity/Activity/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.levels.map((data) => data.id).indexOf(id);
              this.levels.splice(i, 1);
              this.isDelete = false;
              // location.reload();
            });
        }
      });
    },
    async AddOption() {
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("optionA", this.form.optionA);
      data.append("optionB", this.form.optionB);
      data.append("sublevel_id", this.sublevel_id);
      let addNewLevel = await axios
        .post(this.$appName + "/api/option/Option", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewLevel) {
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;
        setTimeout(() => {
          this.$router.push({
            name: "View option",
            params: { id: this.sublevel_id },
          });
        }, 0);
      }
    },
  },
};
</script>

<style>

</style>
