<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <div class="row">
        <div class="col-md-4 mt-2 pl-1">
          <router-link to="/level">
            <button
              type="button"
              class="btn btn-primary ml-2 btn-sm btnst"
              style="background: #1f7dad; border-color: #1f7dad"
            >
              Back
            </button>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <!-- `/sub-level/add/${this.$route.params.id}` -->

          <router-link
            :to="{
              name: 'Add Sublevel',
              params: { idd: this.$route.params.id },
            }"
          >
            <AddButton />
          </router-link>
        </div>

        <BulkAction @bulkActions="bulkActions" />
      </div>
      <div class="row">
        <div class="col-12">
          <h4 style="font-size: 1.5rem; margin-bottom: -11px">
            Sub Levels ({{ levelLenght }})
          </h4>
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />
            <b-table
              :items="subLevels"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>

              <template v-slot:cell(id)="subLevels">
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="subLevels.item.id"
                  name="id[]"
                  number
                />
              </template>
              <template v-slot:cell(activity)="subLevels">
                <router-link
                  :to="{
                    name: 'View activity',
                    params: { id: subLevels.item.id },
                  }"
                >
                  <span style="color: black">{{
                    subLevels.item.activity.length
                  }}</span>
                </router-link>
              </template>

              <template v-slot:cell(action)="subLevels">
                <router-link
                  :to="{
                    name: 'Edit sub level',
                    params: { id: subLevels.item.id, idd: $route.params.id },
                  }"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-pencil"
                  />
                </router-link>

                <a
                  @click="deleteLevel(subLevels.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>

              <template v-slot:cell(status)="subLevels">
                <b-form-checkbox
                  @click.native="singleAct(subLevels.item.id)"
                  v-if="subLevels.item.status == 'activate'"
                  name="check-button"
                  switch
                  checked="true"
                >
                </b-form-checkbox>

                <b-form-checkbox
                  @click.native="singleDct(subLevels.item.id)"
                  v-else
                  name="check-button"
                  switch
                  checked="false"
                >
                </b-form-checkbox>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import AddButton from "../ButtonComponent/AddButton.vue";
import BulkAction from "../ButtonComponent/BulkAction.vue";

export default {
  components: {
    Loader1,
    ToasterDe,
    ToasterAc,
    ToasterD,
    AddButton,
    BulkAction,
  },
  data() {
    return {
      subLevels: [],
      toasterac: false,
      toasterde: false,
      toasterd: false,
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      rows: "",
      count: 0,
      status: "",
      loader: false,

      imgUrl: this.$appName,

      form: new Form({
        status: "",
        id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        // { key: "shortname", label: "Short Name", tdClass: "align-middle" },
        { key: "name", label: "Name", tdClass: "align-middle" },
        { key: "description", label: "Description", tdClass: "align-middle" },
        { key: "status", label: "Status", tdClass: "align-middle" },
        // { key: "activity", label: "Activity", tdClass: "align-middle" },

        // { key: "challenge", label: "Challenge", tdClass: "align-middle" },

        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {
    this.getSubLevel();
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },
    getSubLevel() {
      // localStorage.removeItem("levelId");
      // localStorage.setItem("levelId", this.$route.params.id);
      axios
        .get(this.$appName + `/api/sub_level/show/${this.$route.params.id}`)
        .then((res) => {
          this.subLevels = res.data;
          console.log(this.subLevels);
          this.levelLenght = res.data.length;

          this.rows = res.data.length;
        });
    },

    deleteLevel(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          //  location.reload();

          axios
            .delete(this.$appName + `/api/sub_level/delete/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.subLevels.map((data) => data.id).indexOf(id);
              this.subLevels.splice(i, 1);
              this.isDelete = false;
              // location.reload();
            });
        }
      });
    },
    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;
        // const selected = this.subLevels.map((u) => u.id);
        // this.selected = selected;
        this.count = this.subLevels.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },

    bulkActions(type) {
      if (type == "delete") {
        this.status = "delete";
      } else if (type == "activate") {
        this.status = "activate";
      } else {
        this.status = "deactivate";
      }
      this.changeStatus();
    },

    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);

        if (this.status != "delete") {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;

              axios
                .post(this.$appName + "/api/sub_level/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  if (this.status == "deactivate") {
                    this.toasterde = true;
                  } else {
                    this.toasterac = true;
                  }
                  this.getSubLevel();
                  this.allSelected = false;
                  this.selected = false;
                  // location.reload();
                });
            }
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;

              axios
                .post(this.$appName + "/api/sub_level/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  this.toasterd = true;
                  this.getSubLevel();
                  this.allSelected = !this.allSelected;
                });
            }
          });
        }
      } else {
        alert("Please select atleast one record");
      }
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitUtlity();
    },

    methodClose() {
      location.reload();
    },

    singleAct(id) {
      this.loader = true;
      axios
        .post(this.$appName + `/api/sub_level/changeDeactivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);

          location.reload();
        })
        .catch((error) => {
          this.loader = false;

          console.log(error);
        });
    },
    singleDct(id) {
      this.loader = true;
      axios
        .post(this.$appName + `/api/sub_level/changeActivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);

          location.reload();
        })
        .catch((error) => {
          this.loader = false;

          console.log(error);
        });
    },
  },
};
</script>

<style>
/* .imgg {
  width: 60px !important;
  max-width: 60px !important;
  padding: 0.25rem;
  background-color: #f8f9fe;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
}

.btnn {
  color: gray;
  background-color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.page-item.active .page-link {
  background: #1f7dad;
  border-color: #1f7dad;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.swal2-icon.swal2-warning {
  border-color: #dc3545;
  color: #dc3545;
}
.swal2-styled.swal2-cancel {
  background: #dc3545 !important;
  border-radius: 26px !important;
}
.swal2-styled.swal2-confirm {
  border-radius: 26px !important;
  background: #1f7dad !important;
}

.swal2-styled.swal2-default-outline:focus {
  box-shadow: unset !important;
}
.import_utlity:hover {
  background: #1f7dad;
  color: white;
  border-color: #1f7dad;
}
.import_utlity:focus {
  background: #1f7dad;
  box-shadow: none !important;
  color: white;
  border-color: #1f7dad !important;
}

.table thead th {
  font-weight: 700;
}
select#__BVID__36 {
  margin-right: 3px;

  margin-left: 3px;
}
.btn-secondary {
  border-radius: 26px !important;
}
.btn-primary {
  border-radius: 26px !important;
}
.modal-title {
  font-size: 1.5rem;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: unset;
}
.custom-select {
  margin-left: 3px;
  margin-right: 3px;
}
a.h4.mb-0.text-white.text-uppercase.d-none.d-lg-inline-block.active.router-link-active {
  display: none !important;
} */
</style>
