<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <div>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          style="
            background: #1f7dad;
            border-color: #1f7dad;
            border-radius: 26px;
            font-size: 13px;
            padding: 6px 11px 6px 11px;
          "
          @click="$router.go(-1)"
        >
          Back
        </button>
        <!-- <i class="ni ni-bold-left"></i>  <button>Back</button> -->
      </div>

      <div class="mt-2">
        <h2 style="font-size: 1.5rem; ">Category List</h2>
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="TagCategory"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
            <input type="hidden" v-model="word_id"  id="" />

        <div class="col-12 p-1">
          <div class="row">
            <div
              class="col-md-3 col-lg-3 col-4 mb-2"
              v-for="category in categories"
              :key="category.id"
            >
              <input type="checkbox" v-model="selectCate"   :value="category.id" name="id[]" id="" />
              {{ category.name }}
            </div>
            <!-- <div class="col-4">
              <input type="checkbox" name="" id="" />
              ksd
            </div> -->
          </div>
        </div>
        <br />

        <div class="text-right" style="">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="my-3 btn btn-primary"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
export default {
  components: {
    Loader1,
  },

  data() {
    return {
      errors: [],
      loader: false,
      alert: false,
      categories: [],
      selectCate: [],

      activities: null,
      word_id:this.$route.params.id,
      img_folder: "/images/",
      base_url: this.$appName,
      form: new Form({
        id: "",
        word_id: ""
      }),
    };
  },
  mounted() {
    axios.get(this.$appName + `/api/category/Category`).then((res) => {
      //pushing data to items that will show inside table
      this.categories = res.data;
      console.log(   this.categories );
      this.levelLenght = res.data.length;
      this.rows = res.data.length;
    });

    axios.get(this.$appName + `/api/word/category/${this.$route.params.id}`).then((res) => {
      this.wordCate = res.data;
       this.selectCate = this.wordCate.map(function(object){
          return object.category_id
      });    
   
    });

  },
  methods: {
    async TagCategory() {
      if(this.selectCate == '')
      {
          alert('Please select category')
      }
      else{
      this.loader = true;
      let data = new FormData();
      data.append("id", this.selectCate);
      data.append("word_id", this.word_id);

      await axios
        .post(this.$appName + "/api/word/assignCate", data)
        .then((response) => {
            this.loader = false;
            // location.reload();
          this.$router.push({ path: "/word" });
          console.log(response);
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
        }
    },
  },
};
</script>

<style>
.lablestyle {
  color: #525f7f;
  font-size: 0.935rem;
  font-weight: 600;
}
.form-control {
  padding: 5px 10px !important;
  height: calc(1.5em + 1.01rem + 2px) !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.vs__dropdown-menu {
  /* height: 150px !important; */
  font-size: 0.875rem !important;
}
.vs__search {
  padding: 5px 10px !important;
  color: #8898aa;
  font-size: 0.875rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
}
.vs__selected {
  color: #8898aa;
}
.vs__selected:focus {
  color: #495057;
}

.vs__search:focus {
  color: #495057;

  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.vs--single .vs__selected {
  font-size: 0.875rem !important;
}

.loader-circular {
  width: 120px !important;
  height: 120px !important;
}
#textarea {
  height: auto !important;
}

a.h4.mb-0.text-white.text-uppercase.d-none.d-lg-inline-block.active.router-link-active {
  display: none !important;
}
</style>
