<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <br />

      <BackButton />

      <div class="mt-1">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Category</h2>
        <h2 v-else style="font-size: 1.5rem">Add Category</h2>
      </div>
      <hr />
      <form method="post" @submit.prevent="AddCategory" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
        <base-input v-model="form.id" type="hidden" name="id" placeholder="Enter Name" style="margin-bottom: 1px" />

        <!-- <label class="lablestyle" v-if="form.id">
          Select Word<span class="text-danger"> *</span></label
        >
        <multiselect
          v-if="form.id"
          v-model="form.selectedd"
          :options="words"
          :multiple="true"
          @search-change="onChange"
          :searchable="true"
          :close-on-select="true"
          placeholder="Search word"
          label="tname"
          track-by="id"
          :limit="2"
        >
        </multiselect>

        <br /> -->

        <label class="lablestyle">
          Name<span class="text-danger"> *</span></label>

        <base-input v-model="form.name" type="text" name="name" placeholder="Enter Name" style="margin-bottom: 1px"
          :maxlength="max" />

        <div class="row">
          <div class="col-8">
            <span class="text-danger" style="font-size: 13px" v-if="errors.name">{{ errors.name[0] }}</span>
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max - form.name.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle">
          Image<span class="text-danger"> *</span></label>

        <input style="margin-bottom: 1px" :v-model="form.image" type="file" class="form-control" name="image" id="image"
          placeholder="" accept="image/*" />

        <span class="text-danger" style="font-size: 13px" v-if="errors.image">{{
        errors.image[0]
        }}</span>
        <br />
        <img v-if="form.id" class="imgg" :src="base_url + img_folder + form.image" style="margin-top: -15px" alt="" />
        <div class="text-right">
          <button type="submit" style="background: #1f7dad; border-radius: 26px" class="mt-1 mb-5 btn btn-primary"
            :disabled="form.busy">
            Submit
          </button>
        </div>
      </form>

      <div v-if="form.id">
        <hr />

        <form method="post" @submit.prevent="updateCategory" @keydown="form.onKeydown($event)"
          enctype="multipart/form-data">
          <base-input v-model="form.id" type="hidden" name="id" placeholder="Enter Name" style="margin-bottom: 1px" />
          <label class="lablestyle" v-if="form.id">
            Assign Word<span class="text-danger"> *</span></label>
          <div class="row">
            <div class="col-11">
              <multiselect v-if="form.id" v-model="form.selectedd" :options="words" :multiple="true"
                @search-change="onChange" :searchable="true" :close-on-select="true" placeholder="Search words"
                label="tname" track-by="id" :limit="2" :max-height="161">
              </multiselect>

              <span v-if="error1" class="text-danger" style="font-size: 13px">
                {{ this.errors }}
              </span>
              <br />
            </div>
            <div class="col-1 p-0" style="margin-top: -4px; margin-left: -10px">
              <button type="submit" style="background: #1f7dad; border-radius: 26px" class="mt-1 mb-1 btn btn-primary"
                :disabled="form.busy">
                Submit
              </button>
            </div>
          </div>
        </form>

        <div class="mt-4">
          <div class="row">
            <div class="col-md-4 mb-2 mt-2 pl-2.5">
              <base-button v-if="form.id" type="button" class="btn btn-primary btn-sm my-2 mr-2 btnst"
                style="background: #1f7dad; border-color: #1f7dad" @click="goTo">Add</base-button>
            </div>

            <div class="col-md-8 text-right mt-2 mb-2 p-0">
              <button class="btn btn-danger statusBtn my-3 mr-3 btnst" style="border-radius: 26px" @click="deletee"
                id="delete">
                Delete
              </button>
            </div>
          </div>
          <h2 style="font-size: 1.5rem">Words</h2>
          <div class="col-12">
            <div class="row">
              <div class="col-sm-12 col-md-6 p-0">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                    entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 3px">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right float-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive">
                <form method="post" @submit.prevent="changeStatus" @keydown="form.onKeydown($event)"
                  enctype="multipart/form-data">
                  <input type="hidden" v-model="status" name="status" id="" />

                  <b-table :items="category" :fields="fields" responsive="sm" :per-page="perPage"
                    :current-page="currentPage" :sort-by.sync="sortBy" ref="selectableTable" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" show-empty empty-text="No record found"
                    empty-filtered-text="No search result found" @filtered="onFiltered">
                    <template #head(id)>
                      <input type="checkbox" v-model="allSelected" @change="selectAll" />
                    </template>

                    <template v-slot:cell(id)="category">
                      <input type="checkbox" v-model="selected" :value="category.item.id" name="id[]" number />
                    </template>
                    <template v-slot:cell(tname)="category">
                      <span>{{ category.item.word.tname }}</span>
                    </template>
                    <template v-slot:cell(ename)="category">
                      <span>{{ category.item.word.ename }}</span>
                    </template>

                    <template v-slot:cell(action)="category">
                      <a @click="deleteCategory(category.item.id)" style="font-size: 16px; color: #dc3545">
                        <font-awesome-icon style="font-size: 16px; padding: 0 4px" icon="fa-solid fa-trash-can" />
                      </a>
                    </template>
                  </b-table>
                </form>

                <br />
                <div class="col-sm-12 col-md-6 float-right p-0" style="">
                  <div class="
                      dataTables_paginate
                      paging_simple_numbers
                      float-right
                      mb-3
                    ">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import BackButton from "../ButtonComponent/BackButton.vue";
export default {
  components: {
    Loader1,
    Multiselect,
    ToasterA,
    ToasterU,
    ToasterDe,
    ToasterAc,
    ToasterD,
    BackButton,
  },

  data() {
    return {
      category: [],
      char_names: [],
      alertText: "",
      alertRowNum: "",
      error1: false,
      onFiltered: "",
      rows: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      max: 20,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      toaster: false,
      toasterde: false,
      toasterac: false,

      toasterd: false,

      status: "",
      wordss: [],
      selectedd: null,
      words: [],
      errors: [],
      categoryID: [],
      loader: false,
      alert: false,
      id: 2,

      activities: null,
      img_folder: "/images/",
      base_url: this.$appName,
      form: new Form({
        id: "",
        selectedd: "",
        name: "",
        image: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "tname", label: "Turkish Name", tdClass: "align-middle" },
        { key: "ename", label: "English Name", tdClass: "align-middle" },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },
  mounted() {
    // axios.get(this.$appName + `/api/word/show`).then((res) => {
    //   this.words = res.data;
    // });

    this.getWords();
  },
  created() {
    if (this.$route.params.id != undefined) {
      axios
        .get(this.$appName + `/api/category/Category/${this.$route.params.id}`)
        .then((res) => {
          console.log(res.data.name);
          this.form = res.data;
        });
    }
  },

  methods: {
    getWords() {
      axios
        .get(this.$appName + `/api/category/showWord/${this.$route.params.id}`)
        .then((res) => {
          //pushing data to items that will show inside table
          this.category = res.data;
          this.levelLenght = res.data.length;
          this.rows = res.data.length;
        });
    },
    onChange(query) {
      if (query.length > 0) {
        axios
          .get(
            this.$appName +
            `/api/word/showFilter/${query}/${this.$route.params.id}`
          )
          .then((res) => {
            this.words = res.data;
          });
      }
    },

    goTo() {
      this.$router.push({
        name: "Add utility",
        params: { id: this.$route.params.id },
      });
    },
    updateCategory() {
      if (this.form.selectedd == undefined) {
        this.error1 = true;
        this.errors = "The word field is required.";
      } else {
        this.AddCategory();
      }
    },

    async deletee() {
      // if (this.count > 0) {
      this.status = "delete";
      this.changeStatus();
      // } else {
      //   alert("Please select atleast one record");
      // }
    },
    // async statusCheck(event) {
    //   if (event.target.checked == true) {
    //     this.count = this.count + 1;
    //   } else {
    //     this.count = this.count - 1;
    //   }
    // },
    changeStatus() {
      if (this.selected != "") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;
            let data = new FormData();
            data.append("status", this.status);
            data.append("id", this.selected);
            axios
              .post(this.$appName + "/api/category/deleteCate", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;
                // location.reload();
                this.toasterd = true;
                this.getWords();
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },
    deleteCategory(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          //  location.reload();

          axios
            .delete(this.$appName + `/api/category/deleteWord/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.category.map((data) => data.id).indexOf(id);
              this.category.splice(i, 1);
              this.isDelete = false;
            });
        }
      });
    },
    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;

        this.count = this.category.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },
    async AddCategory() {
      if (this.form.id != "") {
        if (this.form.selectedd != undefined) {
          this.categoryID = this.form.selectedd.map((x) => x.id);
        }
      }
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("selectedWord", this.categoryID);
      data.append("name", this.form.name);
      data.append("image", document.getElementById("image").files[0]);
      await axios
        .post(this.$appName + "/api/category/Category", data)
        .then((response) => {
          this.loader = false;
          this.toaster = true;
          setTimeout(() => {
            if (this.categoryID != "") {
              location.reload();
            } else {
              this.$router.push({ path: "/category" });
            }
          }, 0);
          console.log(response);
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

<style src="">

</style>
