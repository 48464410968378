<template>
  <div class="row">
    <div class="col-12">
      <vue-gallery-slideshow
        :images="getImages"
        :index="index"
        @close="closeSlideShow"
      ></vue-gallery-slideshow>
      <!-- 

      <vue-gallery-slideshow
        v-else
        :images="getImages"
        :index="index"
        @close="index = null"
      ></vue-gallery-slideshow>

       -->
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      sildeShowFlag: false,
    };
  },
  name: "imageSlideshow",
  props: ["getImages", "index"],
  methods: {
    closeSlideShow() {
      this.$emit("sildeImageClose", false);

    },
  },
};
</script>
