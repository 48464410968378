<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <!-- <div>
        <button
          type="button"
          class="btn btn-primary btn-sm btnst"
          style="background: #1f7dad; border-color: #1f7dad"
          @click="$router.go(-1)"
        >
          Back
        </button>
      </div> -->
      <BackButton />
      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Question</h2>
        <h2 v-else style="font-size: 1.5rem">Add Question</h2>
      </div>
      <form
        method="post"
        @submit.prevent="AddQuestion"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <!-- <label class="lablestyle">
          Turkish Sentence<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.t_sentence"
          class="form-control"
          type="text"
          placeholder="Enter Turkish Sentence"
          style="margin-bottom: 1px"
          :maxlength="max1"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.t_sentence"
              >{{ errors.t_sentence[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max1 - form.t_sentence.length"> </span>
            </div>
          </div>
        </div> -->

        <label class="lablestyle">
          Question (Turkish)<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.question"
          @change="questionSearch"
          class="form-control"
          type="text"
          name="name"
          placeholder="Enter Question"
          :maxlength="max4"
          style="margin-bottom: 1px"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.question"
              >{{ errors.question[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max4 - form.question.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle">
          Question (English)<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.e_sentence"
          class="form-control"
          type="text"
          placeholder="Enter Question"
          :maxlength="max2"
          style="margin-bottom: 1px"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.e_sentence"
              >{{ errors.e_sentence[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max2 - form.e_sentence.length"> </span>
            </div>
          </div>
        </div>

        <label class="lablestyle"> Complete Answer (Turkish)</label>
        <input
          v-model="form.tcomplete_answer"
          class="form-control"
          type="text"
          placeholder="Enter Complete Answer"
          :maxlength="max4"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.tcomplete_answer"
              >{{ errors.tcomplete_answer[0] }}</span
            >
          </div>
          <!-- <div class="col-4">
              <div class="text-right" style="color: #6c7075; font-size: 14px">
                <span>Characters </span>
                <span v-text="max4 - form.tcomplete_answer.length"> </span>
              </div>
            </div> -->
          <br />
        </div>

        <label class="lablestyle"> Complete Answer (English)</label>
        <input
          v-model="form.complete_answer"
          class="form-control"
          type="text"
          placeholder="Enter Complete Answer"
          :maxlength="max3"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.complete_answer"
              >{{ errors.complete_answer[0] }}</span
            >
          </div>
          <!-- <div class="col-4">
              <div class="text-right" style="color: #6c7075; font-size: 14px">
                <span>Characters </span>
                <span v-text="max3 - form.complete_answer.length"> </span>
              </div>
            </div> -->
          <br />
        </div>

        <!-- <div>
          <label class="lablestyle">
            Blank Index<span class="text-danger"> *</span></label
          >

          <v-select
            v-model="form.blankIndex"
            :options="options1"
            placeholder="Select Index"
            label="options"
            @input="selectIndex($event)"
          ></v-select>

          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.blankIndex"
            >{{ errors.blankIndex[0] }}</span
          >
          <br />
        </div> -->

        <label class="lablestyle">
          Option1<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.option1"
          class="form-control"
          type="text"
          placeholder="Enter Option"
          style="margin-bottom: 1px"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.option1"
          >{{ errors.option1[0] }}</span
        >
        <br />

        <label class="lablestyle">
          Option2<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.option2"
          class="form-control"
          type="text"
          placeholder="Enter Option"
          style="margin-bottom: 1px"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.option2"
          >{{ errors.option2[0] }}</span
        >
        <br />
        <label class="lablestyle">
          Option3<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.option3"
          class="form-control"
          type="text"
          placeholder="Enter Option"
          style="margin-bottom: 1px"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.option3"
          >{{ errors.option3[0] }}</span
        >
        <br />

        <label class="lablestyle">
          Option4<span class="text-danger"> *</span></label
        >

        <input
          v-model="form.option4"
          class="form-control"
          type="text"
          placeholder="Enter Option"
          style="margin-bottom: 1px"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.option4"
          >{{ errors.option4[0] }}</span
        >
        <br />

        <div>
          <label class="lablestyle">
            Answer<span class="text-danger"> *</span></label
          >

          <v-select
            v-model="selectOption"
            :options="options"
            placeholder="Select Answer"
            label="options"
            @input="selectId($event)"
          ></v-select>

          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.answer"
            >{{ errors.answer[0] }}</span
          >
          <br />

          <label class="lablestyle"> Turkish Sound File</label>

          <input
            v-if="form.id"
            :v-model="form.t_sound_file"
            type="file"
            @change="GetTurkishName"
            style="display: none"
            id="t_sound_file"
            name="t_sound_file"
            accept="audio/*"
            class="form-control"
            placeholder="Enter Turkish Word"
          />
          <input
            v-else
            :v-model="form.t_sound_file"
            type="file"
            id="t_sound_file"
            name="t_sound_file"
            accept="audio/*"
            class="form-control"
            placeholder="Enter Turkish Word"
          />
          <div class="ButtonForFile" v-if="form.id">
            <input
              class=""
              type="button"
              id="loadFileXml"
              value="Choose File"
              onclick="document.getElementById('t_sound_file').click();"
            /><span class="typeButtonText">
              &nbsp;{{
                form.t_sound_file ? form.t_sound_file : "No file chosen"
              }}</span
            >
          </div>
          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.t_sound_file"
            >{{ errors.t_sound_file[0] }}</span
          >
          <br />

          <label class="lablestyle">
            Image<span class="text-danger"> *</span></label
          >
          <input
            class="form-control"
            :v-model="form.image"
            type="file"
            id="image"
            name="image"
            placeholder="Enter Name"
            style="margin-bottom: 1px"
            accept="image/*"
          />

          <img
            v-if="form.id"
            class="imgg mt-2"
            :src="base_url + img_folder + form.image"
            style=""
            alt=""
          />
          <span
            class="text-danger"
            style="font-size: 13px"
            v-if="errors.image"
            >{{ errors.image[0] }}</span
          >
          <br />
        </div>

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterA from "../../ToasterA";
import ToasterU from "../../ToasterU";
import BackButton from "../../ButtonComponent/BackButton.vue";
export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    BackButton,
  },

  data() {
    return {
      errors: [],
      loader: false,
      blankIndex: "",
      blankIndexNo: "",
      options1: [],
      toaster: false,
      option1: "",
      option2: "",
      option3: "",
      option4: "",

      t_sentence: "",
      e_sentence: "",
      max: 100,
      max1: 100,
      max2: 100,
      max3: 50,
      max4: 50,

      options: [
        { options: "Option1" },
        { options: "Option2" },
        { options: "Option3" },
        { options: "Option4" },
      ],
      alert: false,
      level_id: null,
      selectOption: "",
      img_folder: "/images/",
      base_url: this.$appName,
      index: 0,
      totalOptions: 4,
      activities: null,
      form: new Form({
        id: "",
        // name: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        complete_answer: "",

        e_sentence: "",
        t_sentence: "",
        answer: "",
        totalOptions: "",
        image: "",
        optionID1: "",
        optionID3: "",
        optionID2: "",
        optionID4: "",
        t_sound_file: "",
        tcomplete_answer: "",
        blankIndex: "",
        activity_id: "",
        question: "",
      }),
    };
  },

  created() {
    // console.log(this.$appName + `/api/edit/${this.$route.params.id}`);
    if (this.$route.params.id != null) {
      axios
        .get(this.$appName + `/api/question/show/${this.$route.params.id}`)
        .then((res) => {
          // console.log('ddd->',res.data.options[0].options);
          this.form = res.data;
          this.form.option1 = res.data.options[0].options;
          this.form.option2 = res.data.options[1].options;
          this.form.option3 = res.data.options[2].options;
          this.form.option4 = res.data.options[3].options;
          this.optionID1 = res.data.options[0].id;
          this.optionID2 = res.data.options[1].id;
          this.optionID3 = res.data.options[2].id;
          this.optionID4 = res.data.options[3].id;
          this.selectOption = res.data.answer;
          // this.splitTurkName = res.data.t_sound_file.split(/[ ,]+/);
          // this.form.t_sound_file = this.splitTurkName[1];

          var stringArray = res.data.question
            .split(/(\s+)/)
            .filter((e) => e.trim().length > 0);
          this.options1 = stringArray;
          console.log("after dsds--", this.form.question);

          // console.log("anss--", res.data);
        });
    }
  },

  mounted() {
    this.activityID = this.$route.params.acivitityID;

    // this.activityID = localStorage.getItem("activityID");

    // axios
    //   .get(this.$appName + `/api/question/getOption/${this.activityID}`)
    //   .then((res) => {
    //     this.options = res.data;
    //   });
  },

  methods: {
    selectId(e) {
      this.form.answer = e.options;
    },

    GetTurkishName(event) {
      this.form.t_sound_file = event.target.files[0].name;
    },

    // selectIndex(e) {
    //   this.blankIndexNo = this.options1.indexOf(this.form.blankIndex);
    // },

    questionSearch(e) {
      this.form.blankIndex = "";
      var stringArray = this.form.question
        .split(/(\s+)/)
        .filter((e) => e.trim().length > 0);
      this.options1 = stringArray;
      console.log("after septs--", stringArray);
    },
    async AddQuestion() {
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      // data.append("name", this.form.name);
      data.append("option1", this.form.option1);
      data.append("option2", this.form.option2);
      data.append("option3", this.form.option3);
      data.append("option4", this.form.option4);

      console.log("opt4", this.form.option4);
      console.log("optionID4", this.optionID4);
      data.append("t_sentence", this.form.t_sentence);
      data.append("e_sentence", this.form.e_sentence);
      data.append("optionID1", this.optionID1);
      data.append("optionID2", this.optionID2);
      data.append("optionID3", this.optionID3);
      data.append("optionID4", this.optionID4);
      data.append("totalOptions", this.totalOptions);
      data.append("answer", this.form.answer);
      data.append("question", this.form.question);
      data.append("activity_id", this.activityID);
      data.append("blankIndex", this.form.blankIndex);
      data.append("IndexNo", this.blankIndexNo);
      data.append("complete_answer", this.form.complete_answer);
      data.append("tcomplete_answer", this.form.tcomplete_answer);

      data.append("image", document.getElementById("image").files[0]);
      data.append(
        "t_sound_file",
        document.getElementById("t_sound_file").files[0]
      );

      let addNewActivity = await axios
        .post(this.$appName + "/api/question/store7", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewActivity) {
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;
        setTimeout(() => {
          this.$router.push({
            name: "View activity2.3.1",
            params: { idd: this.$activity8 },
          });
        }, 0);
      }
    },
  },
};
</script>

<style>
.vs__dropdown-menu {
  height: auto !important;
  font-size: 0.875rem !important;
}
</style>
