<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <BackButton />
      <div class="mt-2"></div>
      <form
        method="post"
        @submit.prevent="formSubmit"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-12 mb-5">
            <h2 style="font-size: 1.5rem; margin-bottom: -11px">
              Singular Word
            </h2>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-3">
                <input
                  @change="picked($event)"
                  checked="true"
                  type="radio"
                  id="css"
                  value="one"
                  class="mr-1"
                  name="picked"
                />
                <label for="css">Attach Existing</label><br />
              </div>
              <div class="col-6">
                <input
                  @change="picked($event)"
                  type="radio"
                  value="two"
                  id="javascript"
                  name="picked"
                  class="mr-1"
                />
                <label for="javascript">Add New</label>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2 mt-4" v-if="this.checkVal == true">
            <label class="lablestyle">Select Word </label>
            <multiselect
              v-model="selectedWord"
              :options="words"
              :multiple="false"
              @search-change="onChange"
              @select="selectW"
              :searchable="true"
              :close-on-select="true"
              placeholder="Search words"
              label="tname"
              track-by="id"
              :limit="2"
              :max-height="161"
            >
            </multiselect>

            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.selectedWord"
              >{{ errors.selectedWord[0] }}</span
            >
          </div>

          <div class="col-12 mb-2 mt-4" v-else>
            <!-- <form
            method="post"
            @submit.prevent="AddWord"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          > -->
            <label class="lablestyle">
              Turkish Word<span class="text-danger"> *</span></label
            >

            <input
              class="form-control"
              v-model="form.tname"
              type="text"
              name="tname"
              placeholder="Enter Turkish Word"
              style="margin-bottom: 1px"
              :maxlength="max"
            />

            <div class="row">
              <div class="col-8">
                <span
                  class="text-danger"
                  style="font-size: 13px"
                  v-if="errors.tname"
                  >{{ errors.tname[0] }}</span
                >
              </div>
              <div class="col-4">
                <div class="showLength">
                  <span>Characters </span>
                  <span v-text="max - form.tname.length"> </span>
                </div>
              </div>
            </div>

            <label class="lablestyle">
              English Word<span class="text-danger"> *</span></label
            >

            <base-input
              style="margin-bottom: 1px"
              v-model="form.ename"
              type="text"
              name="ename"
              :maxlength="max1"
              placeholder="Enter English Word"
            />

            <div class="row">
              <div class="col-8">
                <span
                  class="text-danger"
                  style="font-size: 13px"
                  v-if="errors.ename"
                  >{{ errors.ename[0] }}</span
                >
              </div>
              <div class="col-4">
                <div class="showLength">
                  <span>Characters </span>
                  <span v-text="max1 - form.ename.length"> </span>
                </div>
              </div>
            </div>

            <!-- <br /> -->
            <div
              style="font-size: 14px"
              class="text-danger"
              v-if="form.errors.has('ename')"
              v-html="form.errors.get('ename')"
            />
            <label class="lablestyle"> Turkish Sound File</label>

            <base-input
              v-model="form.t_sound_file"
              type="file"
              id="t_sound_file"
              name="t_sound_file"
              style="margin-bottom: 1px"
              placeholder="Enter Turkish Word"
              accept="audio/*"
            />
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.t_sound_file"
              >{{ errors.t_sound_file[0] }}</span
            >
            <br />

            <label class="lablestyle"> English Sound File</label>

            <base-input
              style="margin-bottom: 1px"
              v-model="form.e_sound_file"
              type="file"
              id="e_sound_file"
              name="e_sound_file"
              accept="audio/*"
            />
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.e_sound_file"
              >{{ errors.e_sound_file[0] }}</span
            >
            <br />

            <label class="lablestyle">
              Image<span class="text-danger"> *</span></label
            >

            <base-input
              style="margin-bottom: 1px"
              v-model="form.image"
              type="file"
              name="image"
              id="image"
              placeholder=""
              accept="image/*"
            />

            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.image"
              >{{ errors.image[0] }}</span
            >
            <br />
            <div class="text-right">
              <!-- <button
                type="submit"
                style="background: #1f7dad; border-radius: 26px"
                class="mt-1 mb-5 btn btn-primary"
                :disabled="form.busy"
              >
                Submit
              </button> -->
            </div>
            <!-- </form> -->
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-5 mt-5">
            <h2 style="font-size: 1.5rem; margin-bottom: -11px">Plural Word</h2>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-3">
                <input
                  checked="true"
                  @change="picked1($event)"
                  type="radio"
                  value="three"
                  class="mr-1"
                  name="picked1"
                />
                <label for="css">Attach Existing</label><br />
              </div>
              <div class="col-6">
                <input
                  @change="picked1($event)"
                  type="radio"
                  value="four"
                  id="javascript"
                  name="picked1"
                  class="mr-1"
                />
                <label for="javascript">Add New</label>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2 mt-4" v-if="this.checkVal1 == true">
            <input type="hidden" v-model="form.id" />
            <label class="lablestyle">Select Opposite Word </label>
            <multiselect
              v-model="selectedWord1"
              :options="wordss"
              :multiple="false"
              @select="selectW1"
              @search-change="onChange1"
              :searchable="true"
              :close-on-select="true"
              placeholder="Search words"
              label="tname"
              track-by="id"
              :limit="2"
              :max-height="161"
            >
            </multiselect>

            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.selectedWord1"
              >{{ errors.selectedWord1[0] }}</span
            >
          </div>

          <div class="col-12 mb-2 mt-4" v-else>
            <!-- <form
            method="post"
            @submit.prevent="AddWord"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          > -->
            <label class="lablestyle">
              Turkish Word<span class="text-danger"> *</span></label
            >
            <div></div>

            <input
              class="form-control"
              v-model="form.tname1"
              type="text"
              name="tname"
              placeholder="Enter Turkish Word"
              style="margin-bottom: 1px"
              :maxlength="max"
            />

            <div class="row">
              <div class="col-8">
                <span
                  class="text-danger"
                  style="font-size: 13px"
                  v-if="errors.tname1"
                  >{{ errors.tname1[0] }}</span
                >
                <span
                  class="text-danger"
                  style="font-size: 13px"
                  v-if="this.errorC"
                  >{{ "The turkish word has already been taken." }}</span
                >
              </div>
              <div class="col-4">
                <div class="showLength">
                  <span>Characters </span>
                  <span v-text="max - form.tname1.length"> </span>
                </div>
              </div>
            </div>

            <label class="lablestyle">
              English Word<span class="text-danger"> *</span></label
            >

            <base-input
              style="margin-bottom: 1px"
              v-model="form.ename1"
              type="text"
              name="ename"
              :maxlength="max1"
              placeholder="Enter English Word"
            />

            <div class="row">
              <div class="col-8">
                <span
                  class="text-danger"
                  style="font-size: 13px"
                  v-if="errors.ename1"
                  >{{ errors.ename1[0] }}</span
                >
              </div>
              <div class="col-4">
                <div class="showLength">
                  <span>Characters </span>
                  <span v-text="max1 - form.ename1.length"> </span>
                </div>
              </div>
            </div>

            <!-- <br /> -->
            <div
              style="font-size: 14px"
              class="text-danger"
              v-if="form.errors.has('ename')"
              v-html="form.errors.get('ename')"
            />
            <label class="lablestyle"> Turkish Sound File</label>

            <base-input
              v-model="form.t_sound_file1"
              type="file"
              id="t_sound_file1"
              name="t_sound_file"
              style="margin-bottom: 1px"
              placeholder="Enter Turkish Word"
              accept="audio/*"
            />
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.t_sound_file1"
              >{{ errors.t_sound_file1[0] }}</span
            >
            <br />

            <label class="lablestyle"> English Sound File</label>

            <base-input
              style="margin-bottom: 1px"
              v-model="form.e_sound_file1"
              type="file"
              id="e_sound_file1"
              name="e_sound_file"
              accept="audio/*"
            />
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.e_sound_file1"
              >{{ errors.e_sound_file1[0] }}</span
            >
            <br />

            <label class="lablestyle">
              Image<span class="text-danger"> *</span></label
            >

            <base-input
              style="margin-bottom: 1px"
              v-model="form.image1"
              type="file"
              name="image"
              id="image1"
              placeholder=""
              accept="image/*"
            />

            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.image1"
              >{{ errors.image1[0] }}</span
            >
            <!-- </form> -->
          </div>
        </div>
        <div class="col-12 text-right p-0 mt-2">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary"
            :disabled="form.busy"
          >
            submit
          </button>
        </div>
      </form>

     
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import BackButton from "../ButtonComponent/BackButton.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    ToasterDe,
    ToasterAc,
    ToasterD,
    Multiselect,
    BackButton,
  },

  data() {
    return {
      words: [],
      wordss: [],
      toaster: false,
      toasterde: false,
      toasterac: false,
      loader: false,
      toasterd: false,
      errors: [],
      max: 20,
      max1: 20,
      checkVal: true,
      checkVal1: true,
      selectedWord: "",
      selectedWord1: "",
      checkWord: "1",
      checkWord1: "1",
      checkName: "1",
      checkName1: "1",
      errorC: false,
      form: new Form({
        selectedWord: "",
        selectedWord1: "",
        id: "",
        tname: "",
        ename: "",
        tname1: "",
        ename1: "",
        selectedWord_id: "",
        selectedWord1_id: "",
        t_sound_file: "",
        e_sound_file: "",
        t_sound_file1: "",
        e_sound_file1: "",
        image: "",
        image1: "",
        namee: "",
        namee1: "",
      }),
    };
  },

  mounted() {
    if (this.$route.params.id != undefined) {
      axios
        .get(
          this.$appName +
            `/api/activity/getWordSingular/${this.$route.params.id}`
        )
        .then((res) => {
          console.log("check rec", res.data);
          this.form.id = res.data.id;
          this.selectedWord = res.data.singluar;
          this.selectedWord1 = res.data.plural;
        });
    }
  },
  methods: {
    getWords() {
      //   axios.get(this.$appName + `/api/activity/getWords`).then((res) => {
      //     this.category = res.data;
      //     this.levelLenght = res.data.length;
      //     this.rows = res.data.length;
      //   });
    },

    picked(event) {
      // alert(event.target.value);
      if (event.target.value == "two") {
        this.checkVal = false;
      }
      if (event.target.value == "one") {
        this.checkVal = true;
      }
      //   alert( this.checkVal);
    },
    picked1(event) {
      // alert(event.target.value);
      if (event.target.value == "four") {
        this.checkVal1 = false;
      }
      if (event.target.value == "three") {
        this.checkVal1 = true;
      }
    },

    selectW(data) {
      this.checkWord = data.tname;
        console.log(this.checkWord);
    },
    selectW1(data) {
      this.checkWord1 = data.tname;
    },

    onChange(query) {

      // console.log('chh--', this.checkVal1)
      if (query.length > 0) {
        axios
          .get(
            this.$appName +
              `/api/activity/getWords1/${query}/${this.checkWord1}`
          )
          .then((res) => {
            this.words = res.data;
          });
      }
    },

    onChange1(query) {
      if (query.length > 0) {
        axios
          .get(
            this.$appName + `/api/activity/getWords1/${query}/${this.checkWord}`
          )
          .then((res) => {
            this.wordss = res.data;
          });
      }
    },

    async formSubmit() {
      this.loader = true;
      let data = new FormData();
      if (this.selectedWord) {
        data.append("selectedWord", this.selectedWord.tname);
      }
      if (this.selectedWord1) {
        data.append("selectedWord1", this.selectedWord1.tname);
      }

      if (this.form.id) {
        data.append("id", this.form.id);
      }

      if (this.checkVal == false) {
        data.append("tname", this.form.tname);
        data.append("ename", this.form.ename);
        data.append("namee", this.checkName);
        data.append("image", document.getElementById("image").files[0]);
        data.append(
          "t_sound_file",
          document.getElementById("t_sound_file").files[0]
        );
        data.append(
          "e_sound_file",
          document.getElementById("e_sound_file").files[0]
        );
      }

      if (this.checkVal1 == false) {
        data.append("tname1", this.form.tname1);
        data.append("ename1", this.form.ename1);
        data.append("namee1", this.checkName1);
        data.append("image1", document.getElementById("image1").files[0]);
        data.append(
          "t_sound_file1",
          document.getElementById("t_sound_file1").files[0]
        );
        data.append(
          "e_sound_file1",
          document.getElementById("e_sound_file1").files[0]
        );
      }

      let addNewActivity = await axios
        .post(this.$appName + "/api/activity/singularPlural", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });

      if (addNewActivity) {
        if (addNewActivity.data == "duplicate") {
          this.loader = false;
          this.errorC = true;
          setTimeout(() => {
            this.errorC = false;
          }, 3000);

        } else {
          this.errorC = false;

          this.toaster = true;
          this.toasterprop = true;
          this.loader = false;
          setTimeout(() => {
            // location.reload();
            this.$router.push({ path: "/singular_plural/view" });
          }, 0);
        }
      }
    },
  },
};
</script>

<style></style>
