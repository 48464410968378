<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <router-link to="/level/add">
            <AddButton />
          </router-link>
        </div>

        <BulkAction @bulkActions="bulkActions" />
      </div>

      <div class="row">
        <!-- <div class="col-12 text-right p-0" style="margin-left:-6px">
    
          <button
            v-b-modal.modal-1
            class="btn btn-dark statusBtn my-2 mr-2 btnst import_utlity"
          >
            <font-awesome-icon
              style="font-size: 16px; padding: 0 1px"
              icon="fa-solid fa-plus"
            />
            Import Utility
          </button>
          <form
            ref="form"
            @submit.prevent="submitLevel"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <b-modal
              id="modal-1"
              title="Import"
              @cancel="methodClose"
              @close="methodClose"
              @ok="handleOk"
              no-close-on-backdrop
            >
              <base-input
                type="file"
                id="fileimport"
                name="fileimport"
                v-model="form.fileimport"
                style="margin-bottom: 0rem"
              />

              <span
                class="text-danger"
                style="font-size: 13px; margin-top: -10px"
                v-if="errors.file"
                >{{ errors.file[0] }}</span
              >

              <span
                class="text-danger"
                style="font-size: 13px; margin-top: -10px"
                v-if="this.alert"
                >{{ this.alertText }} {{ this.alertRowNum }}
                {{ this.alertDuplicate }}
                <br />
              </span>

              <span
                class="text-success"
                style="font-size: 13px; margin-top: -10px"
                v-if="this.alert"
                >{{ this.alertSuccess }}
              </span>
            </b-modal>
          </form>
        </div> -->

        <div class="col-12">
          <h2 style="font-size: 1.5rem; margin-bottom: -11px">
            Levels ({{ levelLenght }})
          </h2>
        </div>
      </div>

      <br />
      <br />
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />

            <b-table
              :items="levels"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="['name', 'description', 'sub_level']"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>

              <template v-slot:cell(id)="levels">
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="levels.item.id"
                  name="id[]"
                  number
                />
              </template>

              <template v-slot:cell(description)="levels">
                <!-- <div v-if="levels.item.description < 50"> -->
                <span>{{ levels.item.description }} </span>
                <!-- </div> -->
              </template>

              <!-- substring(0,8)+".." -->
              <template v-slot:cell(sub_level)="levels">
                <router-link
                  :to="{
                    name: 'View sub level',
                    params: { id: levels.item.id },
                  }"
                >
                  <span class="linkStyle">{{
                    levels.item.sub_level.length
                  }}</span>
                </router-link>
              </template>
              <template v-slot:cell(status)="levels">
                <b-form-checkbox
                  @click.native="singleAct(levels.item.id)"
                  v-if="levels.item.status == 'activate'"
                  name="check-button"
                  switch
                  checked="true"
                >
                </b-form-checkbox>

                <b-form-checkbox
                  @click.native="singleDct(levels.item.id)"
                  v-else
                  name="check-button"
                  switch
                  checked="false"
                >
                </b-form-checkbox>
                <!-- <font-awesome-icon
                  class="fontIcon"
                  @click="singleAct(levels.item.id)"
                  v-if="levels.item.status == 'activate'"
                  style="font-size: 16px; padding: 0 4px"
                  icon="fa-solid fa-toggle-on"
                />
                <font-awesome-icon
                  class="fontIcon"
                  v-else
                  @click="singleDct(levels.item.id)"
                  style="font-size: 16px; padding: 0 4px"
                  icon="fa-solid fa-toggle-off"
                /> -->
              </template>

              <template v-slot:cell(action)="levels">
                <router-link
                  :to="{
                    name: 'Edit level',
                    params: { id: levels.item.id },
                  }"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-pencil"
                  />
                </router-link>
                <a
                  @click="deleteLevel(levels.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
// import "bootstrap/dist/css/bootstrap.min.css";
// import "datatables.net-bs4";
// import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import AddButton from "../ButtonComponent/AddButton.vue";
import BulkAction from "../ButtonComponent/BulkAction.vue";

export default {
  components: {
    Loader1,
    ToasterDe,
    ToasterAc,
    ToasterD,
    AddButton,
    BulkAction,
  },
  data() {
    return {
      toasterde: false,
      toasterac: false,
      toasterd: false,
      levels: [],
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      rows: "",
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,

      imgUrl: this.$appName,

      form: new Form({
        status: "",
        id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "name", label: "Name", tdClass: "align-middle" },
        { key: "description", label: "Description", tdClass: "align-middle" },
        { key: "status", label: "Status", tdClass: "align-middle" },
        {
          key: "sub_level",
          label: "Sublevel",
          tdClass: "align-middle",
          formatter: (value) => {
            return value.length;
          },
        },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {
    this.getAllLevel();
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },
    getAllLevel() {
      axios.get(this.$appName + "/api/level/Level").then((res) => {
        //pushing data to items that will show inside table
        this.levels = res.data;
        this.levelLenght = res.data.length;
        this.rows = res.data.length;
      });
    },
    deleteLevel(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");

          axios
            .delete(this.$appName + `/api/level/Level/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.levels.map((data) => data.id).indexOf(id);
              this.levels.splice(i, 1);
              this.isDelete = false;
              this.toasterd = true;
              this.levelLenght = this.levels.length;
              this.rows = this.levels.length;
            });
        }
      });
    },
    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;

        this.count = this.levels.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },

    bulkActions(type) {
      if (type == "delete") {
        this.status = "delete";
      } else if (type == "activate") {
        this.status = "activate";
      } else {
        this.status = "deactivate";
      }
      this.changeStatus();
    },

    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);
        if (this.status != "delete") {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;
              axios
                .post(this.$appName + "/api/level/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  if (this.status == "deactivate") {
                    this.toasterde = true;
                  } else {
                    this.toasterac = true;
                  }
                  this.getAllLevel();
                  this.allSelected = false;
                  this.selected = false;
                  // location.reload();
                });
            }
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;
              axios
                .post(this.$appName + "/api/level/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  this.toasterd = true;
                  this.getAllLevel();
                  this.allSelected = false;
                  // location.reload();
                });
            }
          });
        }
      } else {
        alert("Please select atleast one record");
      }
    },

    singleAct(id) {
      this.loader = true;

      axios
        .post(this.$appName + `/api/level/changeDeactivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);

          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },
    singleDct(id) {
      this.loader = true;

      axios
        .post(this.$appName + `/api/level/changeActivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);
          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },

    submitLevel() {
      this.loader = true;
      let data = new FormData();
      data.append("file", document.getElementById("fileimport").files[0]);

      axios
        .post(this.$appName + "/api/level/import", data)
        .then((response) => {
          this.loader = false;
          if (!response.data.message) {
            location.reload();
          }
          // location.reload();
          this.alert = true;
          console.log(response.data);

          //for get erorrs from upload file
          this.alertText = response.data.message;
          this.alertRowNum = response.data.rownum;
          //for get duplicate record errors from upload file
          this.alertText = response.data.message;
          this.alertDuplicate = response.data.dup;

          this.alertSuccess = response.data.sucess;
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
          console.log(this.errors);
        });
    },
  },
};
</script>

<style></style>
