<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <BackButton />
      <div class="mt-2">
        <h2 style="font-size: 1.5rem">Add Word</h2>
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="AddWord"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <input type="hidden" v-model="wordCatId" name="" id="" />
        <label class="lablestyle"
          >Select Character<span class="text-danger"> *</span></label
        >
        <v-select
          :options="characters"
          placeholder="Search Character"
          label="name"
          @input="selectId($event)"
        ></v-select>

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.character"
          >{{ errors.character[0] }}</span
        >
        <br />

        <label class="lablestyle">
          Turkish Word<span class="text-danger"> *</span></label
        >

        <input
          class="form-control"
          v-model="form.tname"
          type="text"
          name="tname"
          placeholder="Enter Turkish Word"
          style="margin-bottom: 1px"
          :maxlength="max"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.tname"
              >{{ errors.tname[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="showLength">
              <span>Characters </span>
              <span v-text="max - form.tname.length"> </span>
            </div>
          </div>
        </div>

        <!-- <br /> -->

        <label class="lablestyle">
          English Word<span class="text-danger"> *</span></label
        >

        <base-input
          style="margin-bottom: 1px"
          v-model="form.ename"
          type="text"
          name="ename"
          :maxlength="max1"
          placeholder="Enter English Word"
        />

        <div class="row">
          <div class="col-8">
            <span
              class="text-danger"
              style="font-size: 13px"
              v-if="errors.ename"
              >{{ errors.ename[0] }}</span
            >
          </div>
          <div class="col-4">
            <div class="showLength">
              <span>Characters </span>
              <span v-text="max1 - form.ename.length"> </span>
            </div>
          </div>
        </div>

        <!-- <br /> -->
        <div
          style="font-size: 14px"
          class="text-danger"
          v-if="form.errors.has('ename')"
          v-html="form.errors.get('ename')"
        />
        <label class="lablestyle"> Turkish Sound File</label>

        <base-input
          v-model="form.t_sound_file"
          type="file"
          id="t_sound_file"
          name="t_sound_file"
          style="margin-bottom: 1px"
          placeholder="Enter Turkish Word"
          accept="audio/*"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.t_sound_file"
          >{{ errors.t_sound_file[0] }}</span
        >
        <br />

        <label class="lablestyle"> English Sound File</label>

        <base-input
          style="margin-bottom: 1px"
          v-model="form.e_sound_file"
          type="file"
          id="e_sound_file"
          name="e_sound_file"
          accept="audio/*"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.e_sound_file"
          >{{ errors.e_sound_file[0] }}</span
        >
        <br />

        <label class="lablestyle">
          Image<span class="text-danger"> *</span></label
        >

        <base-input
          style="margin-bottom: 1px"
          v-model="form.image"
          type="file"
          name="image"
          id="image"
          placeholder=""
          accept="image/*"
        />

        <span class="text-danger" style="font-size: 13px" v-if="errors.image">{{
          errors.image[0]
        }}</span>
        <br />
        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mt-1 mb-5 btn btn-primary"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import BackButton from "../ButtonComponent/BackButton.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    BackButton,
  },

  data() {
    return {
      errors: [],
      loader: false,
      characters: [],
      wordCatId: "",
      selectedId: "",
      toaster: false,
      max: 100,
      max1: 100,
      form: new Form({
        image: "",
        tname: "",
        t_sound_file: "",
        e_sound_file: "",
        ename: "",
        character: "",
        wordCatId: "",
      }),
    };
  },

  mounted() {
    if (this.$route.params.id != undefined) {
      this.wordCatId = this.$route.params.id;
    }

    axios.get(this.$appName + "/api/word/characters").then((res) => {
      //pushing data to items that will show inside table
      this.characters = res.data;
    });
  },

  methods: {
    selectId(e) {
      this.selectedId = e.id;
    },
    async AddWord() {
      this.loader = true;

      let data = new FormData();
      data.append("tname", this.form.tname);
      data.append("ename", this.form.ename);
      data.append("character", this.selectedId);
      data.append("wordCatId", this.wordCatId);
      data.append("image", document.getElementById("image").files[0]);
      data.append(
        "t_sound_file",
        document.getElementById("t_sound_file").files[0]
      );
      data.append(
        "e_sound_file",
        document.getElementById("e_sound_file").files[0]
      );

      await axios
        .post(this.$appName + "/api/word/Word", data)
        .then((response) => {
          this.loader = false;
          this.toaster = true;

          setTimeout(() => {
            console.log(response);

            if (this.wordCatId != "") {
              this.$router.push({
                name: "Edit category",
                params: { id: this.wordCatId },
              });
            } else {
              this.$router.push({ path: "/word" });
            }
          }, 0);
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

<style>
.vs__dropdown-menu {
  height: 150px !important;
  color: black !important;
  font-size: 0.875rem !important;
}
</style>
