<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6 p-3" style="background-color: white">
      <p v-html="this.text"></p>
      <!-- <b>What information we collect about you?</b>
      <p>
        We collect personal information from you when you order goods or
        services from us or use our website. We also collect information when
        you complete any customer survey. Website usage information may also be
        collected using cookies.
      </p>

      <b> Cookies and Google Analytics</b>
      <p>
        Cookies are small text files that are placed on your computer by
        websites that you visit. They are widely used in order to make websites
        work, or work more efficiently, as well as to provide information to the
        owners of the site. This website uses Google Analytics, a web analytics
        service provided by Google, Inc. ("Google"). Google Analytics uses
        "cookies", which are text files saved on your computer, to help the
        website analyze how you use the site.
      </p>
      <b>
        You can also make use of the following rights at any time: Right to
        access
      </b>
      <p>
        You have the right to be informed which data we store about you and how
        we process this data.
      </p>

      <b> Right to rectification </b>
      <p>
        If you notice that stored data is incorrect, you can always ask us to
        correct it.
      </p>
      <b> Right to erasure </b>
      <p>
        You can ask us at any time to delete the data we have stored about you.
      </p> -->
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      text: "",
    };
  },

  created() {
    axios.get(this.$appName + `/api/setting/Setting/${3}`).then((res) => {
      if (res.data.id != undefined) {
        this.text = res.data.privacy_policy;
        console.log("dd", this.form);
      }
    });
  },
};
</script>
