<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <br />

      <BackButton />

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Sub Level</h2>
        <h2 v-else style="font-size: 1.5rem">Add Sub Level</h2>
      </div>
      <hr />
      <form method="post" @submit.prevent="AddSubLevel" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
        <base-input v-model="form.id" type="hidden" name="id" placeholder="Enter Name" style="margin-bottom: 1px" />

        <base-input v-model="this.level_id" type="hidden" name="lavel_id" placeholder="Enter Name"
          style="margin-bottom: 1px" />
        <label class="lablestyle">
          Tag<span class="text-danger"> *</span></label>
        <base-input v-model="form.shortname" type="text" name="shortname" placeholder="Enter Tag"
          style="margin-bottom: 1px" :maxlength="max2" />
        <div class="row">
          <div class="col-8">
            <span class="text-danger" style="font-size: 13px" v-if="errors.shortname">{{ errors.shortname[0] }}</span>
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max2 - form.shortname.length"> </span>
            </div>
          </div>
        </div>
        <label class="lablestyle">
          Name<span class="text-danger"> *</span></label>

        <base-input v-model="form.name" type="text" name="name" placeholder="Enter Name" style="margin-bottom: 1px"
          :maxlength="max" />

        <div class="row">
          <div class="col-8">
            <span class="text-danger" style="font-size: 13px" v-if="errors.name">{{ errors.name[0] }}</span>
          </div>
          <div class="col-4">
            <div class="text-right" style="color: #6c7075; font-size: 14px">
              <span>Characters </span>
              <span v-text="max - form.name.length"> </span>
            </div>
          </div>
        </div>
        <!-- <label class="lablestyle">
          Sub Name</label>
        <base-input v-model="form.sub_name" type="text" name="name" placeholder="Enter Sub Name"
          style="margin-bottom: 1px" />
        <br /> -->

        <label class="lablestyle">
          Description<span class="text-danger"> *</span></label>

        <b-form-textarea id="textarea" v-model="form.description" description="description" class="form-control"
          placeholder="Enter Description" rows="4" :maxlength="max1" max-rows="10"></b-form-textarea>

  
        <div class="row">
          <div class="col-8">
            <span class="text-danger" style="font-size: 13px" v-if="errors.description">{{ errors.description[0]
            }}</span>
          </div>
          <div class="col-4">
            <div class="showLength">
              <span>Characters </span>
              <span v-text="max1 - form.description.length"> </span>
            </div>
          </div>
        </div>

        <br />
        <div class="text-right">
          <button type="submit" style="background: #1f7dad; border-radius: 26px" class="mt-1 mb-5 btn btn-primary"
            :disabled="form.busy">
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import BackButton from "../ButtonComponent/BackButton.vue";
export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    BackButton,
  },

  data() {
    return {
      errors: [],
      loader: false,
      alert: false,
      level_id: null,
      toaster: false,
      activities: null,
      max: 50,
      max2: 50,
      max1: 150,

      form: new Form({
        id: "",
        level_id: "",
        shortname: "",
        name: "",
        sub_name: "",
        description: "",
      }),
    };
  },

  created() {
    // console.log(this.$appName + `/api/edit/${this.$route.params.id}`);
    if (this.$route.params.id != null) {
      axios
        .get(this.$appName + `/api/sub_level/edit/${this.$route.params.id}`)
        .then((res) => {
          console.log("---", res.data);
          this.form = res.data;
        });
    }
  },

  mounted() {
    this.level_id = this.$route.params.idd;
  },

  methods: {
    async AddSubLevel() {
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("level_id", this.level_id);
      data.append("shortname", this.form.shortname);
      data.append("name", this.form.name);
      // data.append("sub_name", this.form.sub_name);
      data.append("description", this.form.description);

      await axios
        .post(this.$appName + "/api/sub_level/store", data)
        .then((response) => {
          this.loader = false;
          this.toaster = true;

          setTimeout(() => {
            this.$router.push({
              name: "View sub level",
              params: { id: this.level_id },
            });
          }, 0);

          console.log(response);
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

<style>
/* .lablestyle {
  color: #525f7f;
  font-size: 0.935rem;
  font-weight: 600;
}
.form-control {
  padding: 5px 10px !important;
  height: calc(1.5em + 1.01rem + 2px) !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.vs__dropdown-menu {
  font-size: 0.875rem !important;
}
.vs__search {
  padding: 5px 10px !important;
  color: #8898aa;
  font-size: 0.875rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
} */
/* .vs__selected {
  color: #8898aa;
}
.vs__selected:focus {
  color: #495057;
}

.vs__search:focus {
  color: #495057;

  -webkit-box-shadow: none !important;
  box-shadow: none !important;
} */
/* .vs--single .vs__selected {
  font-size: 0.875rem !important;
}

.loader-circular {
  width: 120px !important;
  height: 120px !important;
}
#textarea {
  height: auto !important;
} */
/* 
a.h4.mb-0.text-white.text-uppercase.d-none.d-lg-inline-block.active.router-link-active {
  display: none !important;
} */
</style>
