<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <div class="col-12" style="padding-left: 0px; padding-right: 7px">
        <div class="row">
          <div class="col-md-4 mb-2 mt-2 pl-1">
            <router-link to="/number/add">
              <AddButton />
            </router-link>
          </div>

          <DeleteButton @bulkActions="bulkActions" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 style="font-size: 1.5rem; margin-bottom: -11px">
            Numbers ({{ levelLenght }})
          </h2>
        </div>
      </div>

      <br />
      <br />
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />

            <b-table
              :items="levels"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>

              <template v-slot:cell(id)="levels">
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="levels.item.id"
                  name="id[]"
                  number
                />
              </template>
              <template v-slot:cell(t_sound_file)="levels">
                <TurkishSoundTag
                  :imgUrl="imgUrl"
                  :soundUrl="levels.item.t_sound_file"
                />
              </template>

              <template v-slot:cell(action)="levels">
                <router-link
                  :to="{
                    name: 'Edit number',
                    params: { id: levels.item.id },
                  }"
                >
                  <a style="color: #007bff">
                    <font-awesome-icon
                      style="font-size: 16px; padding: 0 4px"
                      icon="fa-solid fa-pencil"
                    />
                  </a>
                </router-link>

                <a
                  @click="deleteLevel(levels.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import Loader1 from "../../Loader1";
import ToasterAc from "../../ToasterAc";
import ToasterDe from "../../ToasterDe";
import ToasterD from "../../ToasterD";
import AddButton from "../../ButtonComponent/AddButton.vue";
import DeleteButton from "../../ButtonComponent/DeleteButton.vue";
import TurkishSoundTag from "../../Components/TurkishSoundTag.vue";
export default {
  components: {
    Loader1,
    ToasterDe,
    ToasterAc,
    ToasterD,
    AddButton,
    DeleteButton,
    TurkishSoundTag,
  },
  data() {
    return {
      toasterde: false,
      toasterac: false,
      toasterd: false,
      levels: [],
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      rows: "",
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,

      imgUrl: this.$appName,

      form: new Form({
        status: "",
        number: "",
        id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "name", label: "Turkish Name", tdClass: "align-middle" },
        { key: "number", label: "Number", tdClass: "align-middle" },

        {
          key: "t_sound_file",
          label: "Turkish Sound File",
          tdClass: "align-middle",
        },
        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {
    this.getAllLevel();
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.levelLenght = filteredItems.length;
      this.currentPage = 1;
    },

    getAllLevel() {
      axios.get(this.$appName + "/api/number/Number").then((res) => {
        //pushing data to items that will show inside table
        this.levels = res.data;
        this.levelLenght = res.data.length;
        this.rows = res.data.length;
      });
    },
    // editLevel(id) {
    //   this.loader = true;
    //   axios.get(this.$appName + `/api/number/Number/${id}`).then((res) => {
    //     this.form = res.data;
    //     this.loader = false;
    //     this.$refs["modal1"].show();
    //   });
    // },
    deleteLevel(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          axios
            .delete(this.$appName + `/api/number/Number/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.levels.map((data) => data.id).indexOf(id);
              this.levels.splice(i, 1);
              this.isDelete = false;
              this.toasterd = true;
              this.levelLenght = this.levels.length;
              this.rows = this.levels.length;
            });
        }
      });
    },
    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;

        this.count = this.levels.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },
    bulkActions(type) {
      if (type == "delete") {
        this.status = "delete";
      } else if (type == "activate") {
        this.status = "activate";
      } else {
        this.status = "deactivate";
      }
      this.changeStatus();
    },
    changeStatus() {
      if (this.selected != "") {
        let data = new FormData();
        data.append("status", this.status);
        data.append("id", this.selected);
        if (this.status != "delete") {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;
              axios
                .post(this.$appName + "/api/number/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  if (this.status == "deactivate") {
                    this.toasterde = true;
                  } else {
                    this.toasterac = true;
                  }
                  this.getAllLevel();
                  this.allSelected = false;
                  this.selected = [];

                  // location.reload();
                });
            }
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loader = true;
              axios
                .post(this.$appName + "/api/number/statusChange", data)
                .then((response) => {
                  console.log(response);
                  this.isDelete = false;
                  this.loader = false;
                  this.toasterd = true;
                  this.getAllLevel();
                  this.allSelected = false;
                  this.selected = [];
                  // location.reload();
                });
            }
          });
        }
      } else {
        alert("Please select atleast one record");
      }
    },

    singleAct(id) {
      this.loader = true;

      axios
        .post(this.$appName + `/api/number/changeDeactivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);

          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },
    singleDct(id) {
      this.loader = true;

      axios
        .post(this.$appName + `/api/number/changeActivate/${id}`)
        .then((response) => {
          this.loader = false;
          console.log(response);
          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },
    // handleOk(bvModalEvent) {
    //   // Prevent modal from closing
    //   bvModalEvent.preventDefault();
    //   // Trigger submit handler
    //   this.submitLevel();
    // },

    // methodClose() {
    //   location.reload();
    // },

    // submitLevel() {
    //   this.loader = true;
    //   let data = new FormData();
    //   data.append("number", this.form.number);
    //   data.append("id", this.form.id);
    //   axios
    //     .post(this.$appName + "/api/number/Number", data)
    //     .then((response) => {
    //       this.loader = false;
    //       location.reload();

    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       this.loader = false;
    //       this.errors = error.response.data.errors;
    //       console.log(this.errors);
    //     });
    // },
  },
};
</script>

<style>
.btnst {
  border-radius: 26px;
  font-size: 15px;
}
</style>
